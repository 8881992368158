<template>
  <b-modal
    @shown="updateItem"
    :title="`${tableTitle}`"
    id="myDtcShowTableFouthType"
    size="xl"
  >
    <b-container fluid class="container-fluid">
      <b-select
        class="selected-year"
        :options="years"
        v-model="selectedYear"
        :style="
          tableTitle.length > 3
            ? `left:${100 + (tableTitle.length - 3) * 18}px`
            : ''
        "
      ></b-select>

      <header class="dtc-grid-header ml-1">
        <div>{{ selectedHeader.column1 }}</div>
        <div>{{ selectedHeader.column2 }}</div>
        <div>{{ selectedHeader.column3 }}</div>
        <div class="header-column4">
          <div class="header-column4-row1">
            <div
              class="header-column4-row1-word"
              v-html="selectedHeader.column4"
            ></div>
          </div>
          <div class="header-column4-row2">
            <div>{{ selectedHeader.columnSecond1 }}</div>
            <div>{{ selectedHeader.columnSecond2 }}</div>
            <div>{{ selectedHeader.columnSecond3 }}</div>
          </div>
        </div>
        <div class="header-column5">
          <div class="header-column5-row1">
            <div
              class="header-column5-row1-word"
              v-html="selectedHeader.column5"
            ></div>
          </div>
          <div class="header-column5-row2">
            <div>{{ selectedHeader.columnSecond1 }}</div>
            <div>{{ selectedHeader.columnSecond2 }}</div>
            <div>{{ selectedHeader.columnSecond3 }}</div>
          </div>
        </div>
        <div v-html="selectedHeader.column6"></div>
      </header>

      <!-- <main v-if="!items.length" class="dtc-grid-header main-dtc-body">
        <div
          style="grid-column: 1 / -1;padding: 20px 0px;font-size:24px;color:#000;"
        >
          暫無資料
        </div>
      </main> -->
      <main
        class="dtc-grid-main main-dtc-body ml-1"
        v-for="(item, i) in items"
        :key="i"
        style="color:rgb(57, 49, 46);font-weight: 500;"
        :style="
          i % 2 == 0 ? `background-color: #fff;` : `background-color:#dddddd;`
        "
      >
        <div class="column1-content" style="font-weight: 560;">
          {{ item.school }}
        </div>
        <div class="column2-content">{{ item.year }}</div>
        <div class="column4-content-out">
          <div
            v-for="(inneritem, j) in item.approvedData"
            :key="`inneritem${j}`"
            class="ellipsis content-row"
          >
            <div
              :style="j == 0 ? 'border-top:0px' : ''"
              style="font-family: Microsoft JhengHei, Helvetica;"
            >
              {{ inneritem.cat }}
            </div>
            <!-- <div
              :style="j == 0 ? 'border-top:0px' : ''"
              v-for="(itemk, k) in 2"
              :key="`inneritemk${k}`"
            >
              {{ inneritem[`semester${k + 1}`] }}
            </div> -->
            <div :style="j == 0 ? 'border-top:0px' : ''">
              {{ $formatPrice(inneritem[`semester1`]) }}
            </div>
            <div :style="j == 0 ? 'border-top:0px' : ''">
              {{ $formatPrice(inneritem[`semester2`]) }}
            </div>
            <div :style="j == 0 ? 'border-top:0px' : ''">
              {{ $formatPrice(inneritem.semestertotal) }}
            </div>
          </div>
        </div>
        <div>{{ $formatPrice(item.approvedTotal) }}</div>
        <div class="column5-content-out">
          <div
            v-for="(inneritem, j) in item.realData"
            :key="`inneritem${j}`"
            class="ellipsis content-row"
          >
            <!-- <div
              :style="j == 0 ? 'border-top:0px' : ''"
              v-for="(itemk, k) in 2"
              :key="`inneritemk${k}`"
            >
              {{ inneritem[`semester${k + 1}`] }}
            </div> -->
            <div :style="j == 0 ? 'border-top:0px' : ''">
              {{ $formatPrice(inneritem[`semester1`]) }}
            </div>
            <div :style="j == 0 ? 'border-top:0px' : ''">
              {{ $formatPrice(inneritem[`semester2`]) }}
            </div>
            <div :style="j == 0 ? 'border-top:0px' : ''">
              {{ $formatPrice(inneritem.semestertotal) }}
            </div>
          </div>
        </div>
        <div>{{ $formatPrice(item.realTotal) }}</div>
        <div>{{ item.rate }}</div>
      </main>
      <footer
        class="dtc-grid-footer main-dtc-body ml-1"
        v-for="(item, i) in itemsTotal"
        :key="`footerResult${i}`"
        style="color: #424242; background-color: #fff;"
      >
        <div class="column1-content" style="font-weight: 560;">總計</div>
        <div class="column4-content-out">
          <div
            v-for="(inneritem, j) in item.approvedData"
            :key="`footerResultinneritem${j}`"
            class="ellipsis content-row"
          >
            <div
              :style="j == 0 ? 'border-top:0px' : ''"
              style="font-family: Microsoft JhengHei, Helvetica;"
            >
              {{ inneritem.cat }}
            </div>
            <!-- <div
              :style="j == 0 ? 'border-top:0px' : ''"
              v-for="(itemk, k) in 2"
              :key="`footerResultinneritemk${k}`"
            >
              {{ inneritem[`semester${k + 1}`] }}
            </div> -->
            <div :style="j == 0 ? 'border-top:0px' : ''">
              {{ $formatPrice(inneritem[`semester1`]) }}
            </div>
            <div :style="j == 0 ? 'border-top:0px' : ''">
              {{ $formatPrice(inneritem[`semester2`]) }}
            </div>
            <div :style="j == 0 ? 'border-top:0px' : ''">
              {{ $formatPrice(inneritem.semestertotal) }}
            </div>
          </div>
        </div>
        <div>{{ $formatPrice(item.approvedTotal) }}</div>
        <div class="column5-content-out">
          <div
            v-for="(inneritem, j) in item.realData"
            :key="`footerResultinneritem${j}`"
            class="ellipsis content-row"
          >
            <!-- <div
              :style="j == 0 ? 'border-top:0px' : ''"
              v-for="(itemk, k) in 2"
              :key="`footerResultinneritemk${k}`"
            >
              {{ inneritem[`semester${k + 1}`] }}
            </div> -->
            <div :style="j == 0 ? 'border-top:0px' : ''">
              {{ $formatPrice(inneritem[`semester1`]) }}
            </div>
            <div :style="j == 0 ? 'border-top:0px' : ''">
              {{ $formatPrice(inneritem[`semester2`]) }}
            </div>
            <div :style="j == 0 ? 'border-top:0px' : ''">
              {{ $formatPrice(inneritem.semestertotal) }}
            </div>
          </div>
        </div>
        <div>{{ $formatPrice(item.realTotal) }}</div>
        <div>{{ item.rate }}</div>
      </footer>
    </b-container>
    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="info"
          class="float-right ml-3"
          @click="$bvModal.hide('myDtcShowTableFouthType')"
          >關閉</b-button
        >
        <b-button variant="primary" class="float-right ml-4">下載</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { store } from "@/store/global.js";
const year = new Date().getFullYear() - 1911;
let years = new Array(8).fill().map((s, i) => year + 1 - i);
let fakeData = [
  {
    school: "國立台灣大學",
    year: 109,
    approvedTotal: 107,
    realTotal: 96,
    rate: "89%",
    approvedData: [
      {
        cat: "在地養成",
        semester1: 12,
        semester2: 31,
        semestertotal: 43,
      },
      {
        cat: "偏鄉護理",
        semester1: 38,
        semester2: 27,
        semestertotal: 64,
      },
    ],
    realData: [
      {
        cat: "在地養成",
        semester1: 12,
        semester2: 26,
        semestertotal: 38,
      },
      {
        cat: "偏鄉護理",
        semester1: 32,
        semester2: 26,
        semestertotal: 58,
      },
    ],
  },
  {
    school: "國立成功大學",
    year: 109,
    approvedTotal: 44,
    realTotal: 44,
    rate: "98%",
    approvedData: [
      {
        cat: "在地養成",
        semester1: 12,
        semester2: 11,
        semestertotal: 22,
      },
      {
        cat: "偏鄉護理",
        semester1: 12,
        semester2: 11,
        semestertotal: 22,
      },
    ],
    realData: [
      {
        cat: "在地養成",
        semester1: 12,
        semester2: 11,
        semestertotal: 22,
      },
      {
        cat: "偏鄉護理",
        semester1: 12,
        semester2: 11,
        semestertotal: 22,
      },
    ],
  },
];

let fakeDataTotal = [
  {
    approvedTotal: 44,
    realTotal: 104,
    rate: "98%",
    approvedData: [
      {
        cat: "在地養成",
        semester1: 12,
        semester2: 11,
        semestertotal: 22,
      },
      {
        cat: "偏鄉護理",
        semester1: 12,
        semester2: 11,
        semestertotal: 22,
      },
    ],
    realData: [
      {
        cat: "在地養成",
        semester1: 12,
        semester2: 11,
        semestertotal: 22,
      },
      {
        cat: "偏鄉護理",
        semester1: 12,
        semester2: 11,
        semestertotal: 22,
      },
    ],
  },
];

let headerObj = {
  obj10: {
    column1: "各培育學校",
    column2: "年度",
    column3: "計畫類別",
    column4: "申請核撥金額/元",
    column5: "核銷金額/元",
    column6: "養成計畫預算執行率/%",
    columnSecond1: "上學期",
    columnSecond2: "下學期",
    columnSecond3: "小計",
  },
};
export default {
  name: "ShowTable",
  data() {
    return {
      indexTable: 0,
      tableTitle: "",
      items: [],
      itemsTotal: [],
      headers: [],
      headerLength: 0,
      allItem: {},
      headerObj,
      selectedHeader: {},
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      fakeData,
      fakeDataTotal,
      years,
      selectedYear: 110,
      zoomSize: 1,
    };
  },
  props: [],
  computed: {},
  methods: {
    async updateItem() {
      this.allItem = { ...store.allTableItem };
      let indexItem = 0;
      indexItem = this.allItem.nowtableIndex;
      this.indexTable = this.allItem.nowtableIndex;

      let data = await this.getData();
      this.setData(data);
      this.setTotalData();

      switch (indexItem) {
        case 10:
          // this.items = this.allItem.table10.items;
          this.tableTitle = this.allItem.table10.title;
          this.headers = this.allItem.table10.header;
          this.headerLength = this.allItem.table10.header.length;
          this.selectedHeader = { ...this.headerObj["obj10"] };
          break;
        default:
          console.log("No Data");
      }
    },
    async getData() {
      const url = `Statistical04/Get?year=${this.selectedYear}`;
      const res = await window.axios.get(url);
      return res;
    },
    setData(data) {
      //左邊實際上是分母，原設定為approvedData，右邊實際上是分子，原設定為realData(實際資料是approved)
      let arr = [];
      arr = data.map((s) => ({
        school: s.SchoolName,
        year: s.Year,
        approvedTotal:
          (Boolean(s.Fee[0])
            ? s.Fee[0].Settlement_1 + s.Fee[0].Settlement_2
            : 0) +
          (Boolean(s.Fee[1])
            ? s.Fee[1].Settlement_1 + s.Fee[1].Settlement_2
            : 0),

        realTotal:
          (Boolean(s.Fee[0]) ? s.Fee[0].Approve_1 + s.Fee[0].Approve_2 : 0) +
          (Boolean(s.Fee[1]) ? s.Fee[1].Approve_1 + s.Fee[1].Approve_2 : 0),

        rate: "",
        // approved left
        // approvedData: [
        realData: [
          {
            cat: "在地養成",
            semester1: Boolean(s.Fee[1]) ? s.Fee[1].Settlement_1 : 0,
            semester2: Boolean(s.Fee[1]) ? s.Fee[1].Settlement_2 : 0,
            semestertotal: Boolean(s.Fee[1])
              ? s.Fee[1].Settlement_1 + s.Fee[1].Settlement_2
              : 0,
          },
          {
            cat: "偏鄉護理",
            semester1: Boolean(s.Fee[0]) ? s.Fee[0].Settlement_1 : 0,
            semester2: Boolean(s.Fee[0]) ? s.Fee[0].Settlement_2 : 0,
            semestertotal: Boolean(s.Fee[0])
              ? s.Fee[0].Settlement_1 + s.Fee[0].Settlement_2
              : 0,
          },
        ],
        // real right
        // realData: [
        approvedData: [
          {
            cat: "在地養成",
            semester1: Boolean(s.Fee[1]) ? s.Fee[1].Approve_1 : 0,
            semester2: Boolean(s.Fee[1]) ? s.Fee[1].Approve_2 : 0,
            semestertotal: Boolean(s.Fee[1])
              ? s.Fee[1].Approve_1 + s.Fee[1].Approve_2
              : 0,
          },
          {
            cat: "偏鄉護理",
            semester1: Boolean(s.Fee[0]) ? s.Fee[0].Approve_1 : 0,
            semester2: Boolean(s.Fee[0]) ? s.Fee[0].Approve_2 : 0,
            semestertotal: Boolean(s.Fee[0])
              ? s.Fee[0].Approve_1 + s.Fee[0].Approve_2
              : 0,
          },
        ],
      }));
      arr = arr.map((s) => {
        s.rate = Boolean(s.approvedTotal)
          ? `${((+s.realTotal / +s.approvedTotal) * 100).toFixed(2)}%`
          : "";
        return s;
      });
      this.items = [...arr];
    },
    setTotalData() {
      let obj = {
        approvedTotal: 0,
        realTotal: 0,
        rate: "",
        approvedData: [
          {
            cat: "在地養成",
            semester1: 0,
            semester2: 0,
            semestertotal: 0,
          },
          {
            cat: "偏鄉護理",
            semester1: 0,
            semester2: 0,
            semestertotal: 0,
          },
        ],
        realData: [
          {
            cat: "在地養成",
            semester1: 0,
            semester2: 0,
            semestertotal: 0,
          },
          {
            cat: "偏鄉護理",
            semester1: 0,
            semester2: 0,
            semestertotal: 0,
          },
        ],
      };

      this.items.forEach((s) => {
        obj.approvedTotal += +s.approvedTotal;
        obj.realTotal += +s.realTotal;
        obj.approvedData[0].semestertotal += +s.approvedData[0].semestertotal;
        obj.approvedData[1].semestertotal += +s.approvedData[1].semestertotal;
        obj.realData[0].semestertotal += +s.realData[0].semestertotal;
        obj.realData[1].semestertotal += +s.realData[1].semestertotal;
        for (let i = 0; i < 2; i += 1) {
          obj.approvedData[0][`semester${i + 1}`] += +s.approvedData[0][
            `semester${i + 1}`
          ];
          obj.approvedData[1][`semester${i + 1}`] += +s.approvedData[1][
            `semester${i + 1}`
          ];
          obj.realData[0][`semester${i + 1}`] += +s.realData[0][
            `semester${i + 1}`
          ];
          obj.realData[1][`semester${i + 1}`] += +s.realData[1][
            `semester${i + 1}`
          ];
        }
      });
      obj.rate = `${(obj.realTotal / obj.approvedTotal).toFixed(2) * 100}%`;
      this.itemsTotal = [obj];
    },
  },
  mounted() {
    // this.getData();
  },
  watch: {
    async selectedYear(v) {
      let data = await this.getData();
      this.setData(data);
      this.setTotalData();
    },
  },
};
</script>

<style lang="scss" scoped>
.dtc-grid-header {
  display: grid;
  // grid-template-columns: 120px 60px 110px 360px 360px 120px;
  grid-template-columns: 190px 60px 130px 520px 520px 188.2px;
  grid-auto-flow: column;
  text-align: center;
  padding-right: 0px;
  font-family: Microsoft JhengHei, Helvetica;
  border: 1px solid #f0f0f0;
  border-bottom: 0px;
  margin-right: 4px;
  color: #ffffff;
  > div {
    border-right: 1px solid #f0f0f0;
    background: #424242;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    // font-size: 14px;
    font-size: 15px;
    padding: 4px 0;
    height: 80px;
    padding-top: 25px;
  }
  > div:last-child {
    border-right: none;
  }
  .header-column4 {
    padding-top: 0px;

    .header-column4-row1 {
      text-align: center;
      height: 50px;
      line-height: 50px;
      .header-column4-row1-word {
      }
    }
    .header-column4-row2 {
      display: grid;
      // grid-template-columns: 90px 90px 180px;
      grid-template-columns: 130px 130px 260px;
      > div {
        border-right: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
        height: 30px;
        line-height: 30px;
      }
    }
  }
  .header-column5 {
    padding-top: 0px;

    .header-column5-row1 {
      text-align: center;
      height: 50px;
      line-height: 50px;
      .header-column5-row1-word {
      }
    }
    .header-column5-row2 {
      display: grid;
      // grid-template-columns: 90px 90px 180px;
      grid-template-columns: 130px 130px 260px;
      > div {
        border-right: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
        height: 30px;
        line-height: 30px;
      }
    }
  }
}
.main-dtc-body {
  &:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  > div {
    line-height: 24px;
  }
}
.dtc-grid-main,
.dtc-grid-footer {
  font-weight: 500;
  display: grid;
  // grid-template-columns: 120px 60px 380px 90px 270px 90px 120px;
  grid-template-columns: 190px 60px 520px 130px 390px 130px 120px;
  margin-right: 4px;
  grid-auto-flow: column;
  text-align: center;
  padding-right: 0px;
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  color: #424242;
  > div {
    border-right: 1px solid #f0f0f0;

    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    // margin-top: 0.5px;
  }
  > div:last-child {
    border-right: none;
  }

  .column4-content-out {
    padding-top: 0;
    padding-bottom: 0;

    font-size: 13px;
    .content-row {
      display: grid;
      // grid-template-columns: 110px repeat(3, 90px);
      grid-template-columns: 130px repeat(3, 130px);
      > div {
        border-right: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
      }
    }
  }
  .column5-content-out {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 13px;
    .content-row {
      display: grid;
      // grid-template-columns: repeat(3, 90px);
      grid-template-columns: repeat(3, 130px);
      > div {
        border-right: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
      }
    }
  }
}
.dtc-grid-footer {
  // grid-template-columns: 180px 380px 90px 270px 90px 120px;
  grid-template-columns: 250px 520px 130px 390px 130px 100px;
  border: 1px solid #f0f0f0;
  color: #424242;
  > div {
    border-right: 1px solid #f0f0f0;
    margin-top: 0px;
  }
  > div:last-child {
    border-right: none;
  }

  .column4-content-out {
    margin-top: 0;
    .content-row {
      > div {
        border-right: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
      }
    }
  }
  .column5-content-out {
    margin-top: 0;
    .content-row {
      > div {
        border-right: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
      }
    }
  }
}
.my-dark {
  > div {
    // background: var(--gray);
    background: #333333;
    color: white;
  }
}

.container-fluid {
  position: relative;
  .selected-year {
    position: absolute;
    top: -65px;
    left: 100px;
    width: 100px;
  }
  .zoom-in-button {
    position: absolute;
    top: -65px;
    left: 230px;
    // width: 100px;
  }
  .zoom-out-button {
    position: absolute;
    top: -65px;
    left: 320px;
    // width: 100px;
  }
}

::v-deep .modal-header {
  background: #7e2ca3;
  color: white;
}
::v-deep .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
::v-deep.modal-body {
  padding-left: 0;
  padding-right: 0;
}
// ::v-deep.modal {
//   background: #101112e8;
// }
::v-deep.btn-primary {
  background: #165b9b;
  border-color: #165b9b;
}
::v-deep .modal-content {
  margin-left: -228px;
  width: 142%;
}
::v-deep .modal {
  background: #000000bd;
}
</style>

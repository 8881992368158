<template>
  <b-modal
    @shown="updateItem"
    :title="`${tableTitle}`"
    id="myDtcShowTableThirdType"
    size="xl"
  >
    <b-container fluid class="container-fluid">
      <b-select
        class="selected-year"
        :options="years"
        v-model="selectedYear"
        :style="
          tableTitle.length > 3
            ? `left:${100 + (tableTitle.length - 3) * 18}px`
            : ''
        "
      ></b-select>
      <header class="dtc-grid-header ml-1">
        <div>{{ commonHeaderObject.school }}</div>
        <div>{{ commonHeaderObject.year }}</div>
        <div>{{ commonHeaderObject.cat }}</div>

        <div class="header-column4">
          <div class="header-column4-row1">
            <div
              class="header-column4-row1-word"
              v-html="commonHeaderObject.country"
            ></div>
          </div>
          <div class="header-column4-row2">
            <div>{{ commonHeaderObject.aboriginal }}</div>
            <div>{{ commonHeaderObject.outlyingIsland }}</div>
            <div>{{ commonHeaderObject.partial }}</div>
          </div>
          <div class="header-column4-row3">
            <div v-html="commonHeaderObject.columnThree1"></div>
            <div v-html="commonHeaderObject.columnThree2"></div>
            <div v-html="commonHeaderObject.columnThree3"></div>
            <div v-html="commonHeaderObject.columnThree4"></div>
            <div v-html="commonHeaderObject.columnThree5"></div>
            <div v-html="commonHeaderObject.columnThree6"></div>
            <div v-html="commonHeaderObject.columnThree7"></div>
            <div v-html="commonHeaderObject.columnThree8"></div>
            <div v-html="commonHeaderObject.columnThree9"></div>
            <div v-html="commonHeaderObject.columnThree10"></div>
            <div v-html="commonHeaderObject.columnThree11"></div>
            <div v-html="commonHeaderObject.columnThree12"></div>
            <div v-html="commonHeaderObject.columnThree13"></div>
            <div v-html="commonHeaderObject.columnThree14"></div>
            <div v-html="commonHeaderObject.columnThree15"></div>
            <div v-html="commonHeaderObject.columnThree16"></div>
            <div v-html="commonHeaderObject.columnThree17"></div>
            <div v-html="commonHeaderObject.columnThree18"></div>
            <div v-html="commonHeaderObject.columnThree19"></div>
            <div v-html="commonHeaderObject.columnThree20"></div>
            <div v-html="commonHeaderObject.columnThree21"></div>
            <div v-html="commonHeaderObject.columnThree22"></div>
            <div v-html="commonHeaderObject.columnThree23"></div>
            <div v-html="commonHeaderObject.columnThree24"></div>
            <div v-html="commonHeaderObject.columnThree25"></div>
            <div v-html="commonHeaderObject.columnThree26"></div>
            <div v-html="commonHeaderObject.columnThree27"></div>
            <div v-html="commonHeaderObject.columnThree28"></div>
            <div v-html="commonHeaderObject.columnThree29"></div>
            <div v-html="commonHeaderObject.columnThree30"></div>
            <div v-html="commonHeaderObject.columnThree31"></div>
            <div v-html="commonHeaderObject.columnThree32"></div>
            <div v-html="commonHeaderObject.columnThree33"></div>
            <div v-html="commonHeaderObject.columnThree34"></div>
            <div v-html="commonHeaderObject.columnThree35"></div>
            <div v-html="commonHeaderObject.columnThree36"></div>
            <div v-html="commonHeaderObject.columnThree37"></div>
            <div v-html="commonHeaderObject.columnThree38"></div>
            <div v-html="commonHeaderObject.columnThree39"></div>
            <div v-html="commonHeaderObject.columnThree40"></div>
            <div v-html="commonHeaderObject.columnThree41"></div>
            <div v-html="commonHeaderObject.columnThree42"></div>
            <div v-html="commonHeaderObject.columnThree43"></div>
          </div>
        </div>
        <div class="header-column4-total" style="border-top:0px">
          {{ commonHeaderObject.total }}
        </div>
      </header>

      <!-- <main v-if="!items.length" class="dtc-grid-header main-dtc-body">
        <div
          style="grid-column: 1 / -1;padding: 20px 0px;font-size:24px;color:#000;"
        >
          暫無資料
        </div>
      </main> -->
      <main
        class="dtc-grid-main main-dtc-body ml-1"
        v-for="(item, i) in items"
        :key="i"
        style="color:rgb(57, 49, 46);font-weight: 500;"
        :style="
          i % 2 == 0 ? `background-color: #fff;` : `background-color:#dddddd;`
        "
      >
        <div class="column1-content" style="font-weight: 560;">
          {{ item.school }}
        </div>
        <div class="column2-content">{{ item.year }}</div>
        <div class="column4-content-out">
          <div
            v-for="(inneritem, j) in item.realData"
            :key="`inneritem${j}`"
            class="ellipsis content-row"
          >
            <div
              :style="j == 0 ? 'border-top:0px' : ''"
              style="font-family: Microsoft JhengHei, Helvetica;"
            >
              {{ inneritem.cat }}
            </div>
            <div
              :style="j == 0 ? 'border-top:0px' : ''"
              v-for="(itemk, k) in 43"
              :key="`inneritemk${k}`"
            >
              {{ inneritem[`region${k + 1}`] }}
            </div>
            <div :style="j == 0 ? 'border-top:0px' : ''">
              {{ inneritem.regiontotal }}
            </div>
          </div>
        </div>
        <div>{{ item.realTotal }}</div>
      </main>
      <footer
        class="dtc-grid-footer main-dtc-body ml-1"
        v-for="(item, i) in itemsTotal"
        :key="`footerResult${i}`"
        style="color:  #212529; background-color: #fff;"
      >
        <div class="column1-content" style="font-weight: 560;">總計</div>
        <div class="column4-content-out">
          <div
            v-for="(inneritem, j) in item.realData"
            :key="`footerResultinneritem${j}`"
            class="ellipsis content-row"
          >
            <div
              :style="j == 0 ? 'border-top:0px' : ''"
              style="font-family: Microsoft JhengHei, Helvetica;"
            >
              {{ inneritem.cat }}
            </div>
            <div
              :style="j == 0 ? 'border-top:0px' : ''"
              v-for="(itemk, k) in 43"
              :key="`footerResultinneritemk${k}`"
            >
              {{ inneritem[`region${k + 1}`] }}
            </div>
            <div :style="j == 0 ? 'border-top:0px' : ''">
              {{ inneritem.regiontotal }}
            </div>
          </div>
        </div>

        <div>{{ item.realTotal }}</div>
      </footer>
    </b-container>
    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="info"
          class="float-right ml-3"
          @click="$bvModal.hide('myDtcShowTableThirdType')"
          >關閉</b-button
        >
        <b-button variant="primary" class="float-right ml-4">下載</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { store } from "@/store/global.js";
const year = new Date().getFullYear() - 1911;
let years = new Array(8).fill().map((s, i) => year + 1 - i);

let commonHeaderObject = {
  columnThree1: "臺北市",
  columnThree2: "新北市",
  columnThree3: "基隆市",
  columnThree4: "宜蘭縣",
  columnThree5: "桃園市",
  columnThree6: "新竹縣",
  columnThree7: "新竹市",
  columnThree8: "苗栗縣",
  columnThree9: "臺中市",
  columnThree10: "彰化縣",
  columnThree11: "南投縣",
  columnThree12: "嘉義市",
  columnThree13: "嘉義縣",
  columnThree14: "雲林縣",
  columnThree15: "台南市",
  columnThree16: "高雄市",
  columnThree17: "屏東縣",
  columnThree18: "花蓮籍",
  columnThree19: "澎湖縣",
  columnThree20: "蘭嶼鄉",
  columnThree21: "綠島鄉",
  columnThree22: "金門縣",
  columnThree23: "連江縣",
  columnThree24: "琉球鄉",
  columnThree25: "蘭嶼鄉",
  columnThree26: "綠島鄉",
  columnThree27: "澎湖縣",
  columnThree28: "金門縣",
  columnThree29: "連江縣",
  columnThree30: "琉球鄉",
  columnThree31: "宜蘭縣",
  columnThree32: "新北市",
  columnThree33: "桃園市",
  columnThree34: "新竹縣",
  columnThree35: "苗栗縣",
  columnThree36: "臺中市",
  columnThree37: "彰化縣",
  columnThree38: "南投縣",
  columnThree39: "雲林縣",
  columnThree40: "嘉義縣",
  columnThree41: "臺南市",
  columnThree42: "高雄市",
  columnThree43: "屏東縣",
  total: "合計",
  school: "各培育學校",
  year: "年度",
  cat: "性別",
  aboriginal: "原住民籍",
  partial: "偏鄉籍",
  outlyingIsland: "離島籍",
  country: "縣市別",
};

export default {
  name: "ShowTable",
  data() {
    return {
      indexTable: 0,
      tableTitle: "",
      items: [],
      itemsTotal: [],
      headers: [],
      headerLength: 0,
      allItem: {},
      commonHeaderObject,
      selectedHeader: {},
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      years,
      selectedYear: 110,
      zoomSize: 1,
    };
  },
  props: [],
  computed: {},
  methods: {
    async updateItem() {
      this.allItem = { ...store.allTableItem };

      let indexItem = 0;
      indexItem = this.allItem.nowtableIndex;
      this.indexTable = this.allItem.nowtableIndex;
      this.tableTitle = this.allItem.table4.title;
      this.headers = this.allItem.table4.header;
      const data = await this.getData();
      this.changeData(data);
      this.setTotalData();
    },
    async getData() {
      //取得培育人數資料集合
      const url = `Statistical05/Get?year=${this.selectedYear}`;
      const res = await window.axios.get(url);
      return res;
    },
    changeData(Data) {
      const arr = Data;
      //getAllSchool
      const arrSchool = arr.map((s) => {
        return s.SchoolName;
      });
      const uniqueArrSchool = [...new Set(arrSchool)];

      let allData = uniqueArrSchool.map((s) => ({
        school: s,
        year: arr[0].Year,
        allThisScjoolData: arr.filter((k) => k.SchoolName == s),
      }));
      //目前先假設資料來的順序都是先女再男生,把資料格式處理成假資料樣式
      allData.forEach(function(s, index, array) {
        s.realData = [
          {
            cat: "男",
            region1: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-臺北")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-臺北")
                ).SubTotal
              : 0,
            region2: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-新北")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-新北")
                ).SubTotal
              : 0,
            region3: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-基隆")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-基隆")
                ).SubTotal
              : 0,
            region4: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-宜蘭")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-宜蘭")
                ).SubTotal
              : 0,
            region5: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-桃園")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-桃園")
                ).SubTotal
              : 0,
            region6: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-新竹縣")
            ).SubTotal,
            region7: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-新竹市")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-新竹市")
                ).SubTotal
              : 0,
            region8: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-苗栗縣")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-苗栗縣")
                ).SubTotal
              : 0,
            region9: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-臺中市")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-臺中市")
                ).SubTotal
              : 0,
            region10: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-彰化縣")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-彰化縣")
                ).SubTotal
              : 0,
            region11: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-南投縣")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-南投縣")
                ).SubTotal
              : 0,
            region12: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-嘉義市")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-嘉義市")
                ).SubTotal
              : 0,
            region13: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-嘉義縣")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-嘉義縣")
                ).SubTotal
              : 0,
            region14: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-雲林縣")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-雲林縣")
                ).SubTotal
              : 0,
            region15: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-臺南市")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-臺南市")
                ).SubTotal
              : 0,
            region16: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-高雄市")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-高雄市")
                ).SubTotal
              : 0,
            region17: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-屏東縣")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-屏東縣")
                ).SubTotal
              : 0,
            region18: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-花蓮")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-花蓮")
                ).SubTotal
              : 0,
            region19: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-澎湖")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-澎湖")
                ).SubTotal
              : 0,
            region20: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-蘭嶼")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-蘭嶼")
                ).SubTotal
              : 0,
            region21: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-綠島")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-綠島")
                ).SubTotal
              : 0,
            region22: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-金門")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-金門")
                ).SubTotal
              : 0,
            region23: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-連江")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-連江")
                ).SubTotal
              : 0,
            region24: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-琉球")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-琉球")
                ).SubTotal
              : 0,
            region25: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("離島-蘭嶼")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("離島-蘭嶼")
                ).SubTotal
              : 0,
            region26: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("離島-綠島")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("離島-綠島")
                ).SubTotal
              : 0,
            region27: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("離島-澎湖")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("離島-澎湖")
                ).SubTotal
              : 0,
            region28: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("離島-金門")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("離島-金門")
                ).SubTotal
              : 0,
            region29: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("離島-連江")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("離島-連江")
                ).SubTotal
              : 0,
            region30: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-琉球")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-琉球")
                ).SubTotal
              : 0,
            region31: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-宜蘭")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-宜蘭")
                ).SubTotal
              : 0,
            region32: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-新北")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-新北")
                ).SubTotal
              : 0,
            region33: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-桃園")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-桃園")
                ).SubTotal
              : 0,
            region34: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-新竹縣")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-新竹縣")
                ).SubTotal
              : 0,
            region35: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-苗栗")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-苗栗")
                ).SubTotal
              : 0,
            region36: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-臺中市")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-臺中市")
                ).SubTotal
              : 0,
            region37: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-彰化縣")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-彰化縣")
                ).SubTotal
              : 0,
            region38: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-南投縣")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-南投縣")
                ).SubTotal
              : 0,
            region39: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-雲林縣")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-雲林縣")
                ).SubTotal
              : 0,
            region40: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-嘉義縣")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-嘉義縣")
                ).SubTotal
              : 0,
            region41: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-臺南市")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-臺南市")
                ).SubTotal
              : 0,
            region42: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-高雄市")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-高雄市")
                ).SubTotal
              : 0,
            region43: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-屏東縣")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-屏東縣")
                ).SubTotal
              : 0,
          },
          {
            cat: "女",
            region1: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-臺北")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-臺北")
                ).SubTotal
              : 0,
            region2: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-新北")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-新北")
                ).SubTotal
              : 0,
            region3: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-基隆")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-基隆")
                ).SubTotal
              : 0,
            region4: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-宜蘭")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-宜蘭")
                ).SubTotal
              : 0,
            region5: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-桃園")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-桃園")
                ).SubTotal
              : 0,
            region6: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-新竹縣")
            ).SubTotal,
            region7: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-新竹市")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-新竹市")
                ).SubTotal
              : 0,
            region8: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-苗栗縣")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-苗栗縣")
                ).SubTotal
              : 0,
            region9: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-臺中市")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-臺中市")
                ).SubTotal
              : 0,
            region10: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-彰化縣")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-彰化縣")
                ).SubTotal
              : 0,
            region11: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-南投縣")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-南投縣")
                ).SubTotal
              : 0,
            region12: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-嘉義市")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-嘉義市")
                ).SubTotal
              : 0,
            region13: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-嘉義縣")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-嘉義縣")
                ).SubTotal
              : 0,
            region14: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-雲林縣")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-雲林縣")
                ).SubTotal
              : 0,
            region15: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-臺南市")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-臺南市")
                ).SubTotal
              : 0,
            region16: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-高雄市")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-高雄市")
                ).SubTotal
              : 0,
            region17: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-屏東縣")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-屏東縣")
                ).SubTotal
              : 0,
            region18: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-花蓮")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-花蓮")
                ).SubTotal
              : 0,
            region19: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-澎湖")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-澎湖")
                ).SubTotal
              : 0,
            region20: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-蘭嶼")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-蘭嶼")
                ).SubTotal
              : 0,
            region21: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-綠島")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-綠島")
                ).SubTotal
              : 0,
            region22: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-金門")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-金門")
                ).SubTotal
              : 0,
            region23: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-連江")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-連江")
                ).SubTotal
              : 0,
            region24: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-琉球")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-琉球")
                ).SubTotal
              : 0,
            region25: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("離島-蘭嶼")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("離島-蘭嶼")
                ).SubTotal
              : 0,
            region26: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("離島-綠島")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("離島-綠島")
                ).SubTotal
              : 0,
            region27: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("離島-澎湖")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("離島-澎湖")
                ).SubTotal
              : 0,
            region28: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("離島-金門")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("離島-金門")
                ).SubTotal
              : 0,
            region29: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("離島-連江")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("離島-連江")
                ).SubTotal
              : 0,
            region30: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-琉球")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-琉球")
                ).SubTotal
              : 0,
            region31: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-宜蘭")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-宜蘭")
                ).SubTotal
              : 0,
            region32: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-新北")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-新北")
                ).SubTotal
              : 0,
            region33: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-桃園")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-桃園")
                ).SubTotal
              : 0,
            region34: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-新竹縣")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-新竹縣")
                ).SubTotal
              : 0,
            region35: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-苗栗")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-苗栗")
                ).SubTotal
              : 0,
            region36: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-臺中市")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-臺中市")
                ).SubTotal
              : 0,
            region37: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-彰化縣")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-彰化縣")
                ).SubTotal
              : 0,
            region38: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-南投縣")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-南投縣")
                ).SubTotal
              : 0,
            region39: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-雲林縣")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-雲林縣")
                ).SubTotal
              : 0,
            region40: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-嘉義縣")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-嘉義縣")
                ).SubTotal
              : 0,
            region41: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-臺南市")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-臺南市")
                ).SubTotal
              : 0,
            region42: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-高雄市")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-高雄市")
                ).SubTotal
              : 0,
            region43: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-屏東縣")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-屏東縣")
                ).SubTotal
              : 0,
          },
        ];
      });
      allData.forEach(function(s) {
        s.realData.forEach(function(k) {
          k.regiontotal =
            k.region1 +
            k.region2 +
            k.region3 +
            k.region4 +
            k.region5 +
            k.region6 +
            k.region7 +
            k.region8 +
            k.region9 +
            k.region10 +
            k.region11 +
            k.region12 +
            k.region13 +
            k.region14 +
            k.region15 +
            k.region16 +
            k.region17 +
            k.region18 +
            k.region19 +
            k.region20 +
            k.region21 +
            k.region22 +
            k.region23 +
            k.region24 +
            k.region25 +
            k.region26 +
            k.region27 +
            k.region28 +
            k.region29 +
            k.region30 +
            k.region31 +
            k.region32 +
            k.region33 +
            k.region34 +
            k.region35 +
            k.region36 +
            k.region37 +
            k.region38 +
            k.region39 +
            k.region40 +
            k.region41 +
            k.region42 +
            k.region43;
        });
      });

      allData.forEach(function(s) {
        s.realTotal = s.realData[0].regiontotal + s.realData[1].regiontotal;
      });
      this.items = allData;
    },
    setTotalData() {
      let obj = {
        realTotal: 0,
        realData: [
          {
            cat: "男",
            region1: 0,
            region2: 0,
            region3: 0,
            region4: 0,
            region5: 0,
            region6: 0,
            region7: 0,
            region8: 0,
            region9: 0,
            region10: 0,
            region11: 0,
            region12: 0,
            region13: 0,
            region14: 0,
            region15: 0,
            region16: 0,
            region17: 0,
            region18: 0,
            region19: 0,
            region20: 0,
            region21: 0,
            region22: 0,
            region23: 0,
            region24: 0,
            region25: 0,
            region26: 0,
            region27: 0,
            region28: 0,
            region29: 0,
            region30: 0,
            region31: 0,
            region32: 0,
            region33: 0,
            region34: 0,
            region35: 0,
            region36: 0,
            region37: 0,
            region38: 0,
            region39: 0,
            region40: 0,
            region41: 0,
            region42: 0,
            region43: 0,
            regiontotal: 0,
          },
          {
            cat: "女",
            region1: 0,
            region2: 0,
            region3: 0,
            region4: 0,
            region5: 0,
            region6: 0,
            region7: 0,
            region8: 0,
            region9: 0,
            region10: 0,
            region11: 0,
            region12: 0,
            region13: 0,
            region14: 0,
            region15: 0,
            region16: 0,
            region17: 0,
            region18: 0,
            region19: 0,
            region20: 0,
            region21: 0,
            region22: 0,
            region23: 0,
            region24: 0,
            region25: 0,
            region26: 0,
            region27: 0,
            region28: 0,
            region29: 0,
            region30: 0,
            region31: 0,
            region32: 0,
            region33: 0,
            region34: 0,
            region35: 0,
            region36: 0,
            region37: 0,
            region38: 0,
            region39: 0,
            region40: 0,
            region41: 0,
            region42: 0,
            region43: 0,
            regiontotal: 0,
          },
        ],
      };

      this.items.forEach((s) => {
        obj.realTotal += +s.realTotal;
        obj.realData[0].regiontotal += +s.realData[0].regiontotal;
        obj.realData[1].regiontotal += +s.realData[1].regiontotal;

        for (let i = 0; i < 43; i += 1) {
          obj.realData[0][`region${i + 1}`] += +s.realData[0][`region${i + 1}`];
          obj.realData[1][`region${i + 1}`] += +s.realData[1][`region${i + 1}`];
        }
      });
      this.itemsTotal = [obj];
    },
  },
  mounted() {
    // this.getData();
  },
  watch: {
    async selectedYear(v) {
      const data = await this.getData();
      this.changeData(data);
      this.setTotalData();
    },
  },
};
</script>

<style lang="scss" scoped>
.dtc-grid-header {
  display: grid;
  // grid-template-columns: 100px 40px 40px 860px 110px;
  grid-template-columns: 190px 50px 50px 1118px 200.2px;
  grid-auto-flow: column;
  text-align: center;
  padding-right: 0px;
  font-family: Microsoft JhengHei, Helvetica;
  border: 1px solid #f0f0f0;
  border-bottom: 0px;
  margin-right: 4px;
  color: #ffffff;
  > div {
    border-right: 1px solid #f0f0f0;
    background: #424242;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 16px;
    padding: 4px 0;
    height: 148px;
    padding-top: 38px;
  }
  > div:last-child {
    border-right: none;
  }

  .header-column4 {
    padding-top: 0px;

    .header-column4-row1 {
      text-align: center;
      height: 55px;
      line-height: 55px;
    }
    .header-column4-row2 {
      display: grid;
      // grid-template-columns: 480px 120px 260px;
      grid-template-columns: 624px 156px 338px;
      > div {
        border-right: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
        height: 25px;
      }
    }
    .header-column4-row3 {
      display: grid;
      // grid-template-columns: repeat(43, 20px);
      grid-template-columns: repeat(43, 26px);
      font-size: 14px;
      > div {
        border-right: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
        height: 68px;
      }
      .header-column4-total {
        font-size: 14px;
      }
    }
  }
}
.main-dtc-body {
  &:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  > div {
    line-height: 24px;
  }
}
.dtc-grid-main,
.dtc-grid-footer {
  display: grid;
  // grid-template-columns: 100px 40px 943px 50px;
  grid-template-columns: 190px 50px 1211px 50px;
  margin-right: 4px;
  grid-auto-flow: column;
  text-align: center;
  padding-right: 0px;
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  color: rgb(57, 49, 46);
  > div {
    border-right: 1px solid #f0f0f0;
    font-weight: 500;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    // margin-top: 0.5px;
  }
  > div:last-child {
    border-right: none;
  }

  .column4-content-out {
    padding-top: 0;
    padding-bottom: 0;

    font-size: 12px;
    .content-row {
      display: grid;
      // grid-template-columns: 40px repeat(43, 20px) 43px;
      grid-template-columns: 50px repeat(43, 26px) 43px;
      > div {
        border-right: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
      }
    }
  }
  .column5-content-out {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 12px;
    .content-row {
      display: grid;
      // grid-template-columns: repeat(22, 16px) 43px;
      grid-template-columns: repeat(22, 22px) 43px;
      > div {
        border-right: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
      }
    }
  }
}
.dtc-grid-footer {
  // grid-template-columns: 140px 943px 50px;
  grid-template-columns: 240px 1211px 60px;
  border: 1px solid #f0f0f0;
  color: rgb(57, 49, 46);

  > div {
    border-right: 1px solid #f0f0f0;
    margin-top: 0px;
  }
  > div:last-child {
    border-right: none;
  }

  .column4-content-out {
    .content-row {
      > div {
        border-right: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
      }
      > div:last-child {
        border-right: none;
      }
    }
  }
  .column5-content-out {
    .content-row {
      > div {
        border-right: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
      }
      > div:last-child {
        border-right: none;
      }
    }
  }
}

.container-fluid {
  position: relative;
  .selected-year {
    position: absolute;
    top: -65px;
    left: 100px;
    width: 100px;
  }
  .zoom-in-button {
    position: absolute;
    top: -65px;
    left: 230px;
    // width: 100px;
  }
  .zoom-out-button {
    position: absolute;
    top: -65px;
    left: 320px;
    // width: 100px;
  }
}

::v-deep .modal-header {
  background: #7e2ca3;
  color: white;
}
::v-deep .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
::v-deep.modal-body {
  padding-left: 0;
  padding-right: 0;
}
// ::v-deep.modal {
//   background: #101112e8;
// }
::v-deep.btn-primary {
  background: #165b9b;
  border-color: #165b9b;
}
::v-deep .modal-content {
  margin-left: -228px;
  width: 142%;
}
::v-deep .modal {
  background: #000000bd;
}
</style>

<script>
//import VueCharts from "vue-chartjs";
import { HorizontalBar, mixins } from "vue-chartjs";
const { reactiveData } = mixins;

export default {
  extends: HorizontalBar,
  name: "Stacked",
  props: ["chartData", "labels"],
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            mode: "index",
            intersect: true,
          },
          datalabels: {
            formatter: function(value, context) {
              // if (context.dataset.replace) {
              //   if (!context.dataset.replaceData[context.dataIndex]) return "";
              //   return (
              //     context.dataset.replaceData[context.dataIndex] + "" + "%"
              //   );
              // }
              // return value;
              if (value == 0) {
                return 0;
              } else {
                return "";
              }
            },
            align: "right",
            anchor: "top",
            color: "blue",
            clamp: false,
            display: true,
            font: {
              weight: "bold",
              size: 16,
            },
          },
        },
        scales: {
          y: {
            min: 10,
            max: 50,
          },
          yAxes: [
            {
              ticks: {
                // suggestedMax: 5,
              },
              max: 50,
              stacked: true,
              barPercentage: 0.6,
            },
          ],
          // xAxes: [
          //   {
          //     stacked: true,
          //   },
          // ],
          xAxes: [
            {
              stacked: true,
              ticks: {
                min: 0,
                callback: function(value, index, values) {
                  return value;
                },
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    async drawReport() {
      this.renderChart(this.chartData, this.options);
    },
  },
  async mounted() {
    this.drawReport();
  },
  watch: {
    chartData(val) {
      this.drawReport();
    },
  },
};
</script>

<style lang="scss" scoped>
.dtc-chart {
  color: white;
}
</style>

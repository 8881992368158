<template>
  <div>
    <GChart type="ColumnChart" :data="chartDataAll" :options="chartOptions" />
  </div>
</template>
<script>
import { GChart } from "vue-google-charts";

export default {
  //   extends: Bar,
  //   name: "Stacked",
  props: ["chartDataAll"],
  data() {
    return {
      // Array will be automatically processed with visualization.arrayToDataTable function
      chartData: [
        ["Genre", "原住民", "離島", "偏鄉", { role: "annotation" }],
        ["schoo1", 10, 24, 20, ""],
        ["schoo2", 16, 22, 23, ""],
        ["schoo3", 28, 19, 29, ""],
      ],
      chartOptions: {
        width: 600,
        height: 330,
        legend: { position: "top", maxLines: 3 },
        bar: { groupWidth: "75%" },
        isStacked: "percent",
        vAxis: {
          minValue: 0,
          ticks: [0, 0.3, 0.6, 0.9, 1],
        },
        hAxis: {
          textStyle: {
            fontSize: 11,
            italic: false,
            fontName: "Arial",
          },
        },
      },
    };
  },
  components: {
    GChart,
  },
  methods: {},
  async mounted() {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.dtc-chart {
  color: white;
}
</style>

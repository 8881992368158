<template>
  <b-modal
    @shown="updateItem"
    title="留任率"
    id="myDtcShowTableLastType"
    size="xl"
  >
    <b-container fluid class="container-fluid">
      <b-select
        class="selected-year"
        :options="years"
        v-model="selectedYear"
      ></b-select>

      <header class="dtc-grid-header ml-1">
        <div>{{ selectedHeader.column1 }}</div>
        <div>{{ selectedHeader.column2 }}</div>
        <div>{{ selectedHeader.column3 }}</div>
        <div class="header-column4">
          <div class="header-column4-row1">
            <div
              class="header-column4-row1-word"
              v-html="selectedHeader.column4"
            ></div>
          </div>
          <div class="header-column4-row2">
            <div>{{ selectedHeader.columnSecond1 }}</div>
            <div>{{ selectedHeader.columnSecond2 }}</div>
            <div>{{ selectedHeader.columnSecond3 }}</div>
            <div></div>
          </div>
          <div class="header-column4-row3">
            <div v-html="commonHeaderObject.columnThree1"></div>
            <div v-html="commonHeaderObject.columnThree2"></div>
            <div v-html="commonHeaderObject.columnThree3"></div>
            <div v-html="commonHeaderObject.columnThree4"></div>
            <div v-html="commonHeaderObject.columnThree5"></div>
            <div v-html="commonHeaderObject.columnThree6"></div>
            <div v-html="commonHeaderObject.columnThree7"></div>
            <div v-html="commonHeaderObject.columnThree8"></div>
            <div v-html="commonHeaderObject.columnThree9"></div>
            <div v-html="commonHeaderObject.columnThree10"></div>
            <div v-html="commonHeaderObject.columnThree11"></div>
            <div v-html="commonHeaderObject.columnThree12"></div>
            <div v-html="commonHeaderObject.columnThree13"></div>
            <div v-html="commonHeaderObject.columnThree14"></div>
            <div v-html="commonHeaderObject.columnThree15"></div>
            <div v-html="commonHeaderObject.columnThree16"></div>
            <div v-html="commonHeaderObject.columnThree17"></div>
            <div v-html="commonHeaderObject.columnThree18"></div>
            <div v-html="commonHeaderObject.columnThree19"></div>
            <div v-html="commonHeaderObject.columnThree20"></div>
            <div v-html="commonHeaderObject.columnThree21"></div>
            <div v-html="commonHeaderObject.columnThree22"></div>
            <div class="header-column4-total" style="border-top:0px">
              {{ commonHeaderObject.total }}
            </div>
          </div>
        </div>
        <div class="header-column5">
          <div
            class="header-column5-row1"
            :style="isNeedChangLineHeight ? 'line-height: 56px;' : ''"
          >
            <div
              class="header-column5-row1-word"
              v-html="selectedHeader.column5"
            ></div>
          </div>
          <div class="header-column5-row2">
            <div>{{ selectedHeader.columnSecond1 }}</div>
            <div>{{ selectedHeader.columnSecond2 }}</div>
            <div>{{ selectedHeader.columnSecond3 }}</div>
            <div></div>
          </div>
          <div class="header-column5-row3">
            <div v-html="commonHeaderObject.columnThree1"></div>
            <div v-html="commonHeaderObject.columnThree2"></div>
            <div v-html="commonHeaderObject.columnThree3"></div>
            <div v-html="commonHeaderObject.columnThree4"></div>
            <div v-html="commonHeaderObject.columnThree5"></div>
            <div v-html="commonHeaderObject.columnThree6"></div>
            <div v-html="commonHeaderObject.columnThree7"></div>
            <div v-html="commonHeaderObject.columnThree8"></div>
            <div v-html="commonHeaderObject.columnThree9"></div>
            <div v-html="commonHeaderObject.columnThree10"></div>
            <div v-html="commonHeaderObject.columnThree11"></div>
            <div v-html="commonHeaderObject.columnThree12"></div>
            <div v-html="commonHeaderObject.columnThree13"></div>
            <div v-html="commonHeaderObject.columnThree14"></div>
            <div v-html="commonHeaderObject.columnThree15"></div>
            <div v-html="commonHeaderObject.columnThree16"></div>
            <div v-html="commonHeaderObject.columnThree17"></div>
            <div v-html="commonHeaderObject.columnThree18"></div>
            <div v-html="commonHeaderObject.columnThree19"></div>
            <div v-html="commonHeaderObject.columnThree20"></div>
            <div v-html="commonHeaderObject.columnThree21"></div>
            <div v-html="commonHeaderObject.columnThree22"></div>
            <div class="header-column5-total" style="border-top:0px">
              {{ commonHeaderObject.total }}
            </div>
          </div>
        </div>
        <div v-html="selectedHeader.column6"></div>
      </header>

      <main
        class="dtc-grid-main main-dtc-body ml-1"
        v-for="(item, i) in allItems"
        :key="i"
        style="color:rgb(57, 49, 46);font-weight: 500;"
        :style="
          i % 2 == 0 ? `background-color: #fff;` : `background-color:#dddddd;`
        "
      >
        <div class="column1-content" style="font-weight: 560;">
          {{ item.school }}
        </div>
        <div class="column2-content">{{ item.year }}</div>
        <div class="column4-content-out">
          <div
            v-for="(inneritem, j) in item.approvedData"
            :key="`inneritem${j}`"
            class="ellipsis content-row"
          >
            <div
              :style="j == 0 ? 'border-top:0px' : ''"
              style="font-family: Microsoft JhengHei, Helvetica;"
            >
              {{ inneritem.cat }}
            </div>
            <div
              :style="j == 0 ? 'border-top:0px' : ''"
              v-for="(itemk, k) in 22"
              :key="`inneritemk${k}`"
            >
              {{ inneritem[`region${k + 1}`] }}
            </div>
            <div :style="j == 0 ? 'border-top:0px' : ''">
              {{ inneritem.regiontotal }}
            </div>
          </div>
        </div>
        <div>{{ item.approvedTotal }}</div>
        <div class="column5-content-out">
          <div
            v-for="(inneritem, j) in item.realData"
            :key="`inneritem${j}`"
            class="ellipsis content-row"
          >
            <div
              :style="j == 0 ? 'border-top:0px' : ''"
              v-for="(itemk, k) in 22"
              :key="`inneritemk${k}`"
            >
              {{ inneritem[`region${k + 1}`] }}
            </div>
            <div :style="j == 0 ? 'border-top:0px' : ''">
              {{ inneritem.regiontotal }}
            </div>
          </div>
        </div>
        <div>{{ item.realTotal }}</div>
        <div>{{ item.rate }}</div>
      </main>
      <footer
        class="dtc-grid-footer main-dtc-body ml-1"
        v-for="(item, i) in allItemsTotal"
        :key="`footerResult${i}`"
        style="color: #212529; background-color: #fff;"
      >
        <div class="column1-content" style="font-weight:560">總計</div>
        <div class="column4-content-out">
          <div
            v-for="(inneritem, j) in item.approvedData"
            :key="`footerResultinneritem${j}`"
            class="ellipsis content-row"
          >
            <div
              :style="j == 0 ? 'border-top:0px' : ''"
              style="font-family: Microsoft JhengHei, Helvetica;"
            >
              {{ inneritem.cat }}
            </div>
            <div
              :style="j == 0 ? 'border-top:0px' : ''"
              v-for="(itemk, k) in 22"
              :key="`footerResultinneritemk${k}`"
            >
              {{ inneritem[`region${k + 1}`] }}
            </div>
            <div :style="j == 0 ? 'border-top:0px' : ''">
              {{ inneritem.regiontotal }}
            </div>
          </div>
        </div>
        <div>{{ item.approvedTotal }}</div>
        <div class="column5-content-out">
          <div
            v-for="(inneritem, j) in item.realData"
            :key="`footerResultinneritem${j}`"
            class="ellipsis content-row"
          >
            <div
              :style="j == 0 ? 'border-top:0px' : ''"
              v-for="(itemk, k) in 22"
              :key="`footerResultinneritemk${k}`"
            >
              {{
                isNaN(inneritem[`region${k + 1}`])
                  ? "-"
                  : inneritem[`region${k + 1}`]
              }}
            </div>
            <div :style="j == 0 ? 'border-top:0px' : ''">
              {{ isNaN(inneritem.regiontotal) ? "-" : inneritem.regiontotal }}
            </div>
          </div>
        </div>
        <div>{{ item.realTotal }}</div>
        <div>{{ item.rate }}</div>
      </footer>
    </b-container>
    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="info"
          class="float-right ml-3"
          @click="$bvModal.hide('myDtcShowTableLastType')"
          >關閉</b-button
        >
        <b-button variant="primary" class="float-right ml-4">下載</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { store } from "@/store/global.js";
const year = new Date().getFullYear() - 1911;
let years = new Array(8).fill().map((s, i) => year + 1 - i);

let commonHeaderObject = {
  columnThree1: "花蓮籍",
  columnThree2: "南投籍",
  columnThree3: "其他",
  columnThree4: "蘭嶼鄉",
  columnThree5: "綠島鄉",
  columnThree6: "澎湖縣",
  columnThree7: "金門縣",
  columnThree8: "連江縣",
  columnThree9: "琉球鄉",
  columnThree10: "宜蘭縣",
  columnThree11: "新北市",
  columnThree12: "桃園市",
  columnThree13: "新竹縣",
  columnThree14: "苗栗縣",
  columnThree15: "臺中市",
  columnThree16: "彰化縣",
  columnThree17: "南投縣",
  columnThree18: "雲林縣",
  columnThree19: "嘉義縣",
  columnThree20: "臺南市",
  columnThree21: "高雄市",
  columnThree22: "屏東縣",
  total: "小計",
};
let headerObj = {
  obj9: {
    column1: "各培育學校",
    column2: "年度",
    column3: "性別",
    column4: "留任/人",
    column5: "服務期滿/人",
    column6: "留任率%",
    columnSecond1: "原住民",
    columnSecond2: "離島籍",
    columnSecond3: "偏鄉",
    columnSecond4: "小計",
  },
};
//nullRealData--right data
let nullRealData = [
  {
    cat: "男",
    region1: "-",
    region2: "-",
    region3: "-",
    region4: "-",
    region5: "-",
    region6: "-",
    region7: "-",
    region8: "-",
    region9: "-",
    region10: "-",
    region11: "-",
    region12: "-",
    region13: "-",
    region14: "-",
    region15: "-",
    region16: "-",
    region17: "-",
    region18: "-",
    region19: "-",
    region20: "-",
    region21: "-",
    region22: "-",
    regiontotal: "-",
  },
  {
    cat: "女",
    region1: "-",
    region2: "-",
    region3: "-",
    region4: "-",
    region5: "-",
    region6: "-",
    region7: "-",
    region8: "-",
    region9: "-",
    region10: "-",
    region11: "-",
    region12: "-",
    region13: "-",
    region14: "-",
    region15: "-",
    region16: "-",
    region17: "-",
    region18: "-",
    region19: "-",
    region20: "-",
    region21: "-",
    region22: "-",
    regiontotal: "-",
  },
];
export default {
  name: "ShowTable",
  data() {
    return {
      indexTable: 0,
      tableTitle: "",
      items: [],
      headers: [],
      headerLength: 0,
      allItem: {},
      headerObj,
      commonHeaderObject,
      selectedHeader: {},
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      // fakeData,
      // fakeDataTotal,
      years,
      selectedYear: 110,
      leftItems: [],
      rightItems: [],
      allItems: [],
      allItemsTotal: [],
      nullRealData,
    };
  },
  props: [],
  computed: {
    isNeedChangLineHeight() {
      return (
        +this.indexTable == 6 ||
        +this.indexTable == 7 ||
        +this.indexTable == 8 ||
        +this.indexTable == 9
      );
    },
  },
  methods: {
    async updateItem() {
      this.leftItems = [];
      this.rightItems = [];
      this.allItem = { ...store.allTableItem };

      let indexItem = 0;
      indexItem = this.allItem.nowtableIndex;
      this.indexTable = this.allItem.nowtableIndex;

      let leftData = "";
      let rightData = "";

      leftData = await this.getCountryServiceData("留任");
      rightData = await this.getCountryServiceData("服務期滿");
      // this.items = this.allItem.table8.items;

      this.selectedHeader = { ...this.headerObj["obj9"] };

      this.setLeftData(leftData);
      this.setRightData(rightData);
      this.combineData();
      this.setTotalData();
    },
    async getData() {},
    combineData() {
      let arr = [];

      arr = this.leftItems.map((s, i) => ({
        school: s.school,
        year: s.year,
        approvedTotal: s.approvedTotal,
        // realTotal: this.rightItems[i].realTotal,
        // realData: this.rightItems[i].realData,
        realTotal: this.rightItems.find((k) => k.school == s.school)
          ? this.rightItems.find((k) => k.school == s.school).realTotal
          : "-",
        realData: this.rightItems.find((k) => k.school == s.school)
          ? this.rightItems.find((k) => k.school == s.school).realData
          : this.nullRealData,

        rate: this.rightItems.find((k) => k.school == s.school)
          ? `${Math.floor(
              (this.rightItems.find((k) => k.school == s.school).realTotal /
                s.approvedTotal) *
                100
            )}%`
          : "-",
        approvedData: s.approvedData,
      }));
      this.allItems = arr;
    },
    setTotalData() {
      let obj = {
        approvedTotal: 0,
        realTotal: 0,
        rate: "",
        approvedData: [
          {
            cat: "男",
            region1: 0,
            region2: 0,
            region3: 0,
            region4: 0,
            region5: 0,
            region6: 0,
            region7: 0,
            region8: 0,
            region9: 0,
            region10: 0,
            region11: 0,
            region12: 0,
            region13: 0,
            region14: 0,
            region15: 0,
            region16: 0,
            region17: 0,
            region18: 0,
            region19: 0,
            region20: 0,
            region21: 0,
            region22: 0,
            regiontotal: 0,
          },
          {
            cat: "女",
            region1: 0,
            region2: 0,
            region3: 0,
            region4: 0,
            region5: 0,
            region6: 0,
            region7: 0,
            region8: 0,
            region9: 0,
            region10: 0,
            region11: 0,
            region12: 0,
            region13: 0,
            region14: 0,
            region15: 0,
            region16: 0,
            region17: 0,
            region18: 0,
            region19: 0,
            region20: 0,
            region21: 0,
            region22: 0,
            regiontotal: 0,
          },
        ],
        realData: [
          {
            cat: "男",
            region1: 0,
            region2: 0,
            region3: 0,
            region4: 0,
            region5: 0,
            region6: 0,
            region7: 0,
            region8: 0,
            region9: 0,
            region10: 0,
            region11: 0,
            region12: 0,
            region13: 0,
            region14: 0,
            region15: 0,
            region16: 0,
            region17: 0,
            region18: 0,
            region19: 0,
            region20: 0,
            region21: 0,
            region22: 0,
            regiontotal: 0,
          },
          {
            cat: "女",
            region1: 0,
            region2: 0,
            region3: 0,
            region4: 0,
            region5: 0,
            region6: 0,
            region7: 0,
            region8: 0,
            region9: 0,
            region10: 0,
            region11: 0,
            region12: 0,
            region13: 0,
            region14: 0,
            region15: 0,
            region16: 0,
            region17: 0,
            region18: 0,
            region19: 0,
            region20: 0,
            region21: 0,
            region22: 0,
            regiontotal: 0,
          },
        ],
      };

      this.allItems.forEach((s) => {
        obj.approvedTotal += +s.approvedTotal;
        obj.realTotal += +s.realTotal;
        obj.approvedData[0].regiontotal += +s.approvedData[0].regiontotal;
        obj.approvedData[1].regiontotal += +s.approvedData[1].regiontotal;
        obj.realData[0].regiontotal += +s.realData[0].regiontotal;
        obj.realData[1].regiontotal += +s.realData[1].regiontotal;
        for (let i = 0; i < 22; i += 1) {
          obj.approvedData[0][`region${i + 1}`] += +s.approvedData[0][
            `region${i + 1}`
          ];
          obj.approvedData[1][`region${i + 1}`] += +s.approvedData[1][
            `region${i + 1}`
          ];
          obj.realData[0][`region${i + 1}`] += +s.realData[0][`region${i + 1}`];
          obj.realData[1][`region${i + 1}`] += +s.realData[1][`region${i + 1}`];
        }
      });
      obj.rate = `${Math.floor((obj.realTotal / obj.approvedTotal) * 100)}%`;
      this.allItemsTotal = [obj];
    },
    async getCountryServiceData(key) {
      //取得資料集合
      const url = `Statistical10/Get?year=${this.selectedYear}&category=${key}`;
      const res = await window.axios.get(url);
      return res;
    },

    async setLeftData(Data) {
      //設定左半邊的分母數據
      const arr = Data;
      //getAllSchool
      const arrSchool = arr.map((s) => {
        return s.SchoolName;
      });
      const uniqueArrSchool = [...new Set(arrSchool)];

      let allData = uniqueArrSchool.map((s) => ({
        school: s,
        year: arr[0].Year,
        allThisScjoolData: arr.filter((k) => k.SchoolName == s),
      }));
      //目前先假設資料來的順序都是先女再男生,把資料格式處理成假資料樣式
      allData.forEach(function(s) {
        s.approvedData = [
          {
            cat: "男",
            region1: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-花蓮")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-花蓮")
                ).SubTotal
              : 0,
            region2: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-南投")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-南投")
                ).SubTotal
              : 0,
            region3: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-其他")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-其他")
                ).SubTotal
              : 0,
            region4: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("離島-蘭嶼")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("離島-蘭嶼")
                ).SubTotal
              : 0,
            region5: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("離島-綠島")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("離島-綠島")
                ).SubTotal
              : 0,
            region6: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("離島-澎湖")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("離島-澎湖")
                ).SubTotal
              : 0,
            region7: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("離島-金門")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("離島-金門")
                ).SubTotal
              : 0,
            region8: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("離島-連江")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("離島-連江")
                ).SubTotal
              : 0,
            region9: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("離島-琉球")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("離島-琉球")
                ).SubTotal
              : 0,
            region10: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-宜蘭")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-宜蘭")
                ).SubTotal
              : 0,
            region11: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-新北")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-新北")
                ).SubTotal
              : 0,
            region12: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-桃園")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-桃園")
                ).SubTotal
              : 0,
            region13: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-新竹")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-新竹")
                ).SubTotal
              : 0,
            region14: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-苗栗")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-苗栗")
                ).SubTotal
              : 0,
            region15: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-臺中")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-臺中")
                ).SubTotal
              : 0,
            region16: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-彰化")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-彰化")
                ).SubTotal
              : 0,
            region17: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-南投")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-南投")
                ).SubTotal
              : 0,
            region18: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-雲林")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-雲林")
                ).SubTotal
              : 0,
            region19: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-嘉義")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-嘉義")
                ).SubTotal
              : 0,
            region20: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-臺南")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-臺南")
                ).SubTotal
              : 0,
            region21: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-高雄")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-高雄")
                ).SubTotal
              : 0,
            region22: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-屏東")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-屏東")
                ).SubTotal
              : 0,
          },
          {
            cat: "女",
            region1: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-花蓮")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-花蓮")
                ).SubTotal
              : 0,
            region2: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-南投")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-南投")
                ).SubTotal
              : 0,
            region3: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-其他")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-其他")
                ).SubTotal
              : 0,
            region4: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("離島-蘭嶼")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("離島-蘭嶼")
                ).SubTotal
              : 0,
            region5: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("離島-綠島")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("離島-綠島")
                ).SubTotal
              : 0,
            region6: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("離島-澎湖")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("離島-澎湖")
                ).SubTotal
              : 0,
            region7: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("離島-金門")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("離島-金門")
                ).SubTotal
              : 0,
            region8: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("離島-連江")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("離島-連江")
                ).SubTotal
              : 0,
            region9: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("離島-琉球")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("離島-琉球")
                ).SubTotal
              : 0,
            region10: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-宜蘭")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-宜蘭")
                ).SubTotal
              : 0,
            region11: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-新北")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-新北")
                ).SubTotal
              : 0,
            region12: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-桃園")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-桃園")
                ).SubTotal
              : 0,
            region13: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-新竹")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-新竹")
                ).SubTotal
              : 0,
            region14: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-苗栗")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-苗栗")
                ).SubTotal
              : 0,
            region15: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-臺中")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-臺中")
                ).SubTotal
              : 0,
            region16: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-彰化")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-彰化")
                ).SubTotal
              : 0,
            region17: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-南投")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-南投")
                ).SubTotal
              : 0,
            region18: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-雲林")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-雲林")
                ).SubTotal
              : 0,
            region19: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-嘉義")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-嘉義")
                ).SubTotal
              : 0,
            region20: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-臺南")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-臺南")
                ).SubTotal
              : 0,
            region21: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-高雄")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-高雄")
                ).SubTotal
              : 0,
            region22: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-屏東")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-屏東")
                ).SubTotal
              : 0,
          },
        ];
      });
      allData.forEach(function(s) {
        s.approvedData.forEach(function(k) {
          k.regiontotal =
            k.region1 +
            k.region2 +
            k.region3 +
            k.region4 +
            k.region5 +
            k.region6 +
            k.region7 +
            k.region8 +
            k.region9 +
            k.region10 +
            k.region11 +
            k.region12 +
            k.region13 +
            k.region14 +
            k.region15 +
            k.region16 +
            k.region17 +
            k.region18 +
            k.region19 +
            k.region20 +
            k.region21 +
            k.region22;
        });
      });

      allData.forEach(function(s) {
        s.approvedTotal =
          s.approvedData[0].regiontotal + s.approvedData[1].regiontotal;
      });
      this.leftItems = allData;
    },
    async setRightData(Data) {
      //設定右半邊的分子數據
      const arr = Data;
      //getAllSchool
      const arrSchool = arr.map((s) => {
        return s.SchoolName;
      });
      const uniqueArrSchool = [...new Set(arrSchool)];

      let allData = uniqueArrSchool.map((s) => ({
        school: s,
        year: arr[0].Year,
        allThisScjoolData: arr.filter((k) => k.SchoolName == s),
      }));
      //目前先假設資料來的順序都是先女再男生,把資料格式處理成假資料樣式
      allData.forEach(function(s) {
        s.realData = [
          {
            cat: "男",
            region1: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-花蓮")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-花蓮")
                ).SubTotal
              : 0,
            region2: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-南投")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-南投")
                ).SubTotal
              : 0,
            region3: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("原住民-其他")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("原住民-其他")
                ).SubTotal
              : 0,
            region4: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("離島-蘭嶼")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("離島-蘭嶼")
                ).SubTotal
              : 0,
            region5: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("離島-綠島")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("離島-綠島")
                ).SubTotal
              : 0,
            region6: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("離島-澎湖")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("離島-澎湖")
                ).SubTotal
              : 0,
            region7: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("離島-金門")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("離島-金門")
                ).SubTotal
              : 0,
            region8: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("離島-連江")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("離島-連江")
                ).SubTotal
              : 0,
            region9: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("離島-琉球")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("離島-琉球")
                ).SubTotal
              : 0,
            region10: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-宜蘭")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-宜蘭")
                ).SubTotal
              : 0,
            region11: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-新北")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-新北")
                ).SubTotal
              : 0,
            region12: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-桃園")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-桃園")
                ).SubTotal
              : 0,
            region13: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-新竹")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-新竹")
                ).SubTotal
              : 0,
            region14: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-苗栗")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-苗栗")
                ).SubTotal
              : 0,
            region15: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-臺中")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-臺中")
                ).SubTotal
              : 0,
            region16: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-彰化")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-彰化")
                ).SubTotal
              : 0,
            region17: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-南投")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-南投")
                ).SubTotal
              : 0,
            region18: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-雲林")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-雲林")
                ).SubTotal
              : 0,
            region19: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-嘉義")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-嘉義")
                ).SubTotal
              : 0,
            region20: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-臺南")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-臺南")
                ).SubTotal
              : 0,
            region21: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-高雄")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-高雄")
                ).SubTotal
              : 0,
            region22: s.allThisScjoolData[1].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-屏東")
            )
              ? s.allThisScjoolData[1].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-屏東")
                ).SubTotal
              : 0,
          },
          {
            cat: "女",
            region1: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-花蓮")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-花蓮")
                ).SubTotal
              : 0,
            region2: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-南投")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-南投")
                ).SubTotal
              : 0,
            region3: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("原住民-其他")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("原住民-其他")
                ).SubTotal
              : 0,
            region4: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("離島-蘭嶼")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("離島-蘭嶼")
                ).SubTotal
              : 0,
            region5: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("離島-綠島")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("離島-綠島")
                ).SubTotal
              : 0,
            region6: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("離島-澎湖")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("離島-澎湖")
                ).SubTotal
              : 0,
            region7: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("離島-金門")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("離島-金門")
                ).SubTotal
              : 0,
            region8: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("離島-連江")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("離島-連江")
                ).SubTotal
              : 0,
            region9: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("離島-琉球")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("離島-琉球")
                ).SubTotal
              : 0,
            region10: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-宜蘭")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-宜蘭")
                ).SubTotal
              : 0,
            region11: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-新北")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-新北")
                ).SubTotal
              : 0,
            region12: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-桃園")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-桃園")
                ).SubTotal
              : 0,
            region13: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-新竹")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-新竹")
                ).SubTotal
              : 0,
            region14: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-苗栗")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-苗栗")
                ).SubTotal
              : 0,
            region15: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-臺中")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-臺中")
                ).SubTotal
              : 0,
            region16: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-彰化")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-彰化")
                ).SubTotal
              : 0,
            region17: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-南投")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-南投")
                ).SubTotal
              : 0,
            region18: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-雲林")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-雲林")
                ).SubTotal
              : 0,
            region19: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-嘉義")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-嘉義")
                ).SubTotal
              : 0,
            region20: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-臺南")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-臺南")
                ).SubTotal
              : 0,
            region21: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-高雄")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-高雄")
                ).SubTotal
              : 0,
            region22: s.allThisScjoolData[0].Quantity.find((k) =>
              k.CountryName.includes("偏鄉-屏東")
            )
              ? s.allThisScjoolData[0].Quantity.find((k) =>
                  k.CountryName.includes("偏鄉-屏東")
                ).SubTotal
              : 0,
          },
        ];
      });
      allData.forEach(function(s) {
        s.realData.forEach(function(k) {
          k.regiontotal =
            k.region1 +
            k.region2 +
            k.region3 +
            k.region4 +
            k.region5 +
            k.region6 +
            k.region7 +
            k.region8 +
            k.region9 +
            k.region10 +
            k.region11 +
            k.region12 +
            k.region13 +
            k.region14 +
            k.region15 +
            k.region16 +
            k.region17 +
            k.region18 +
            k.region19 +
            k.region20 +
            k.region21 +
            k.region22;
        });
      });

      allData.forEach(function(s) {
        s.realTotal = s.realData[0].regiontotal + s.realData[1].regiontotal;
      });
      this.rightItems = allData;
    },
  },
  mounted() {
    // this.getData();
  },
  watch: {
    async selectedYear(v) {
      const indexItem = this.indexTable;
      let leftData = "";
      let rightData = "";

      leftData = await this.getCountryServiceData("留任");
      rightData = await this.getCountryServiceData("服務期滿");

      this.setLeftData(leftData);
      this.setRightData(rightData);
      this.combineData();
      this.setTotalData();
    },
  },
};
</script>

<style lang="scss" scoped>
.dtc-grid-header {
  display: grid;
  // grid-template-columns: 100px 40px 40px 445px 445px 60px;
  grid-template-columns: 190px 50px 50px 589px 589px 139.5px;
  grid-auto-flow: column;
  text-align: center;
  padding-right: 0px;
  font-family: Microsoft JhengHei, Helvetica;
  border: 1px solid #f0f0f0;
  border-bottom: 0px;
  margin-right: 4px;
  color: #ffffff;
  > div {
    border-right: 1px solid #f0f0f0;
    background: #424242;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 16px;
    padding: 4px 0;
    height: 148px;
    padding-top: 38px;
  }
  > div:last-child {
    border-right: none;
  }
  .header-column4 {
    padding-top: 0px;

    .header-column4-row1 {
      text-align: center;
      height: 55px;
      line-height: 55px;
    }
    .header-column4-row2 {
      display: grid;
      // grid-template-columns: 48px 96px 208px 93px;
      grid-template-columns: 66px 132px 286px 105px;
      > div {
        border-right: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
        height: 25px;
      }
    }
    .header-column4-row3 {
      display: grid;
      // grid-template-columns: repeat(22, 16px) 93px;
      grid-template-columns: repeat(22, 22px) 105px;
      font-size: 14px;
      > div {
        border-right: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
        height: 68px;
      }
      .header-column4-total {
        font-size: 14px;
      }
    }
  }
  .header-column5 {
    padding-top: 0px;

    .header-column5-row1 {
      text-align: center;
      height: 55px;
      line-height: 55px;
    }
    .header-column5-row2 {
      display: grid;
      // grid-template-columns: 48px 96px 208px 93px;
      grid-template-columns: 66px 132px 286px 105px;
      > div {
        border-right: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
        height: 25px;
      }
    }
    .header-column5-row3 {
      display: grid;
      // grid-template-columns: repeat(22, 16px) 93px;
      grid-template-columns: repeat(22, 22px) 105px;
      font-size: 14px;
      > div {
        border-right: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
        height: 68px;
      }
      .header-column5-total {
        font-size: 14px;
      }
    }
  }
}
.main-dtc-body {
  &:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  > div {
    line-height: 24px;
  }
}
.dtc-grid-main,
.dtc-grid-footer {
  display: grid;
  // grid-template-columns: 100px 40px 435px 50px 395px 50px 60px;
  grid-template-columns: 190px 50px 589px 50px 529px 60px 60px;
  margin-right: 4px;
  grid-auto-flow: column;
  text-align: center;
  padding-right: 0px;
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  color: rgb(57, 49, 46);
  > div {
    border-right: 1px solid #f0f0f0;

    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    // margin-top: 0.5px;
  }
  > div:last-child {
    border-right: none;
  }

  .column4-content-out {
    padding-top: 0;
    padding-bottom: 0;

    font-size: 12px;
    .content-row {
      display: grid;
      // grid-template-columns: 40px repeat(22, 16px) 43px;
      grid-template-columns: 50px repeat(22, 22px) 55px;
      > div {
        border-right: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
      }
    }
  }
  .column5-content-out {
    padding-top: 0;
    padding-bottom: 0;
    font-size: 12px;
    .content-row {
      display: grid;
      // grid-template-columns: repeat(22, 16px) 43px;
      grid-template-columns: repeat(22, 22px) 45px;
      > div {
        border-right: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
      }
    }
  }
}
.dtc-grid-footer {
  // grid-template-columns: 140px 435px 50px 395px 50px 60px;
  grid-template-columns: 240px 589px 50px 529px 60px 60px;
  border: 1px solid #f0f0f0;
  // color: #212529;
  color: rgb(57, 49, 46);
  font-weight: 500;
  > div {
    border-right: 1px solid #f0f0f0;
    margin-top: 0px;
  }
  > div:last-child {
    border-right: none;
  }

  .column4-content-out {
    .content-row {
      > div {
        border-right: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
      }
      > div:last-child {
        border-right: none;
      }
    }
  }
  .column5-content-out {
    .content-row {
      > div {
        border-right: 1px solid #f0f0f0;
        border-top: 1px solid #f0f0f0;
      }
      > div:last-child {
        border-right: none;
      }
    }
  }
}

.container-fluid {
  position: relative;
  .selected-year {
    position: absolute;
    top: -65px;
    left: 100px;
    width: 100px;
  }
  .zoom-in-button {
    position: absolute;
    top: -65px;
    left: 230px;
    // width: 100px;
  }
  .zoom-out-button {
    position: absolute;
    top: -65px;
    left: 320px;
    // width: 100px;
  }
}

::v-deep .modal-header {
  background: #7e2ca3;
  color: white;
}
::v-deep .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
::v-deep.modal-body {
  padding-left: 0;
  padding-right: 0;
}

::v-deep.modal {
  background: #36373a75;
}
::v-deep.btn-primary {
  background: #165b9b;
  border-color: #165b9b;
}
::v-deep .modal-content {
  margin-left: -228px;
  width: 142%;
}
::v-deep .modal {
  background: #000000bd;
}
</style>

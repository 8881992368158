<template>
  <b-modal
    @shown="updateItem"
    :title="`${tableTitle}`"
    id="myDtcShowTable"
    size="xl"
  >
    <b-container fluid>
      <header class="dtc-grid-search ml-1 mb-2">
        <b-input-group prepend="年度區間">
          <b-select
            :options="yearsOption"
            v-model="selectedStartYear"
          ></b-select>
          <div class="mx-3 mt-1">至</div>
          <b-select :options="yearsOption" v-model="selectedEndYear"></b-select>
        </b-input-group>
        <b-input-group prepend="籍別">
          <b-select :options="catOption" v-model="selectedCat"></b-select>
        </b-input-group>
        <b-input-group prepend="性別">
          <b-select :options="genderOption" v-model="selectedGender"></b-select>
        </b-input-group>
        <b-input-group prepend="培育學校">
          <b-select :options="schoolOption" v-model="selectedSchool"></b-select>
        </b-input-group>
      </header>
      <header class="dtc-grid-search2 ml-1 mb-2">
        <b-input-group prepend="醫事人員類別">
          <b-select
            :options="medicalCategoryOption"
            v-model="selectedMedicalCategory"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="服務縣市(現況)">
          <b-select
            :options="countryOption"
            v-model="selectedCountry"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="服務醫療機構(現況)">
          <b-select
            :options="hospitalOption"
            v-model="selectedHospital"
          ></b-select>
        </b-input-group>
      </header>
      <header class="dtc-grid-search3 ml-1 mb-2">
        <div class="word">留任於原住民族或離島地區服務之留任率</div>
        <b-button variant="success" @click="calNumOne" size="sm">計算</b-button>
        <div class="number">{{ number1 }}</div>
        <div class="percent">%</div>
        <div class="word">留任原機構服務之留任率</div>
        <b-button
          variant="warning"
          @click="calNumTwo"
          size="sm"
          style="color:#fff"
          >計算</b-button
        >
        <div class="number">{{ number2 }}</div>
        <div class="percent">%</div>
      </header>
      <header class="dtc-grid-header ml-1" style="border-bottom:0px">
        <div
          v-for="(item, i) in headers"
          :key="`${i}${item.key}`"
          :title="item.text"
        >
          {{ item.text }}
        </div>
      </header>
      <!-- <main v-if="!items.length" class="dtc-grid-header main-dtc-body">
        <div
          style="grid-column: 1 / -1;padding: 20px 0px;font-size:24px;color:#000;"
        >
          暫無資料
        </div>
      </main> -->
      <main
        class="dtc-grid-content main-dtc-body ml-1"
        v-for="(item, i) in 2"
        :key="i"
        style="color:rgb(57, 49, 46);font-weight: 500;"
        :style="
          i % 2 == 0 ? `background-color: #fff;` : `background-color:#dddddd;`
        "
        :class="i + 1 == 2 ? 'last-main' : ''"
      >
        <div
          v-for="(inneritem, j) in headers"
          :key="`${inneritem.text}${j}${inneritem.key}`"
          :title="`${item[inneritem.key]}`"
          class="ellipsis"
        >
          {{ item[inneritem.key] ? item[inneritem.key] : "暫無資料" }}
        </div>
      </main>
      <div class="comment-word ml-1 mr-1 my-2">
        <div>※註1:</div>
        <div>
          留任於原住民族或離島地區服務之留任率計算:
          服務期滿後仍留任於原住民族或離島地區服務者/服務期滿人數，該指標是廣義定義留任率，只要繼續留在原鄉或離島地區從事醫事服務就算
        </div>
      </div>
      <div class="comment-word ml-1">
        <div>※註2:</div>
        <div>
          留任原機構服務之留任率計算: 服務期滿後仍留任原機構服務者/服務期滿人數
        </div>
      </div>
    </b-container>
    <template v-slot:modal-footer>
      <div class="w-100">
        <b-button
          variant="info"
          class="float-right ml-3"
          @click="$bvModal.hide('myDtcShowTable')"
          >關閉</b-button
        >
        <b-button variant="primary" class="float-right ml-4">下載</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { store } from "@/store/global.js";
const headers = [
  {
    key: "Category",
    text: "醫事人員類別",
  },
  {
    key: "Name",
    text: "姓名",
  },
  {
    key: "Gender",
    text: "性別",
  },
  {
    key: "Birthday",
    text: "出生日期",
  },
  {
    key: "Id",
    text: "身分證字號",
  },
  {
    key: "EnrollmentYear",
    text: "入學年",
  },
  {
    key: "GraduationYear",
    text: "畢業年",
  },
  {
    key: "SchoolName",
    text: "培育學校",
  },
  {
    key: "Domicile",
    text: "籍別",
  },
  {
    key: "ServiceSituation",
    text: "服務情形",
  },
  {
    key: "ServiceStatus",
    text: "服務現況",
  },
  {
    key: "ServiceUnits",
    text: "服務單位",
  },
  {
    key: "ServiceArea",
    text: "服務地區",
  },
];
const year = new Date().getFullYear() - 1911;
let yearsOption = new Array(8).fill().map((s, i) => year + 1 - i);

let catOption = [{ value: "", text: "全部" }, "原住民籍", "離島籍", "偏鄉籍"];

let genderOption = [
  { value: "", text: "全部" },
  {
    value: "b",
    text: "男",
  },
  {
    value: "g",
    text: "女",
  },
];
let medicalCategoryOption = [
  { value: "", text: "全部" },
  "中醫師",
  "西醫師",
  "助產士",
  "助產師",
  "呼吸治療師",
  "營養師",
  "牙醫師",
  "牙體技術師",
  "物理治療師",
  "物理治療生",
  "職能治療師",
  "職能治療生",
  "聽力師",
  "臨床心理師",
  "藥劑生",
  "藥師",
  "語言治療師",
  "諮商心理師",
  "護士",
  "護理師",
  "醫事放射士",
  "醫事放射師",
  "醫事檢驗師",
  "醫事檢驗生",
  "驗光師",
];
const countryOption = [
  { text: "全部", value: "" },
  "基隆市",
  "嘉義市",
  "台北市",
  "嘉義縣",
  "新北市",
  "台南市",
  "桃園市",
  "高雄市",
  "新竹市",
  "屏東縣",
  "新竹縣",
  "台東縣",
  "苗栗縣",
  "花蓮縣",
  "台中市",
  "宜蘭縣",
  "彰化縣",
  "澎湖縣",
  "南投縣",
  "金門縣",
  "雲林縣",
  "連江縣",
];
export default {
  name: "ShowTable",
  data() {
    return {
      tableTitle: "",
      items: [],
      headers,
      allItem: {},
      variants: [
        "warning",
        "dark",
        "light",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      selectedStartYear: new Date().getFullYear() - 1913,
      selectedEndYear: new Date().getFullYear() - 1911,
      yearsOption,
      selectedCat: "",
      catOption,
      selectedGender: "",
      genderOption,
      selectedMedicalCategory: "",
      medicalCategoryOption,
      selectedSchool: null,
      schoolOption: [],
      selectedCountry: "",
      countryOption,
      selectedHospital: null,
      hospitalOption: [],
      number1: 0,
      number2: 0,
    };
  },
  props: [],
  computed: {},
  methods: {
    calNumOne() {
      this.number1 = (Math.random() * 100).toFixed(2);
    },
    calNumTwo() {
      this.number2 = (Math.random() * 100).toFixed(2);
    },
    updateItem() {
      //getTableDtata
      this.allItem = { ...store.allTableItem };
      let indexItem = 0;
      indexItem = this.allItem.nowtableIndex;
      switch (indexItem) {
        case 9:
          this.items = this.allItem.table9.items;
          this.tableTitle = this.allItem.table9.title;
          break;
        default:
          console.log("No Data");
      }
      //getSchoolOption
      const schools = JSON.parse(localStorage.dtcSchoolList);
      this.schoolOption = schools.map((s) => ({
        value: s.SchoolCode,
        text: s.SchoolName,
      }));
      this.schoolOption.unshift({ value: null, text: "全部" });
    },
    async getData() {},
  },
  mounted() {
    // this.getData();
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.dtc-grid-search,
.dtc-grid-search2,
.dtc-grid-search3 {
  display: grid;
  grid-template-columns: 420px 300px 320px 300px repeat(34, max-content);
  grid-gap: 10px;
  color: rgb(57, 49, 46);
}

.dtc-grid-search3 {
  grid-template-columns: 360px 100px 40px 20px 320px 100px 40px 20px;
  color: #212529;
  .word {
    text-align: center;
    background: #0379fd;
    color: #fff;
    border: 1px solid rgb(195, 195, 195);
    line-height: 30.88px;
  }
  .number {
    text-align: center;
    line-height: 30.88px;
    font-size: 20px;
  }
  .percent {
    line-height: 30.88px;
    font-size: 20px;
  }
}
.dtc-grid-header {
  display: grid;
  grid-template-columns: 100px 70px 60px 100px 130px 60px 60px 110px 80px 90px 100px repeat(
      2,
      1fr
    );
  grid-auto-flow: column;
  text-align: center;
  padding-right: 0px;
  color: #ffffff;
  border: 1px solid #f0f0f0;
  border-bottom: 0px;
  margin-right: 4px;
  > div {
    border-right: 1px solid #f0f0f0;
    background: #424242;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    white-space: nowrap;
  }
  > div:last-child {
    border-right: none;
  }
}
.dtc-grid-content {
  display: grid;
  grid-template-columns: 100px 70px 60px 100px 130px 60px 60px 110px 80px 90px 100px repeat(
      2,
      1fr
    );
  grid-auto-flow: column;
  text-align: center;
  padding-right: 0px;
  color: #ffffff;
  border: 1px solid #f0f0f0;
  border-bottom: 0px;
  margin-right: 4px;
  > div {
    font-family: Microsoft JhengHei, Helvetica;
    border-right: 1px solid #f0f0f0;
    color: rgb(57, 49, 46);
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    white-space: nowrap;
  }
  > div:last-child {
    border-right: none;
  }
}
.last-main {
  border-bottom: 1px solid #f0f0f0;
}
.my-dark {
  > div {
    background: var(--gray);
    color: #f0f0f0;
  }
}
.main-dtc-body {
  &:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  > div {
    line-height: 24px;
  }
}
.comment-word {
  font-size: 12px;
  display: grid;
  grid-template-columns: 40px max-content;
  color: rgb(57, 49, 46);
  font-family: Microsoft JhengHei, Helvetica;
}
::v-deep .modal-header {
  background: #7e2ca3;
  color: white;
}
::v-deep .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
::v-deep.modal-body {
  padding-left: 0;
  padding-right: 0;
}
// ::v-deep.modal {
//   background: #101112e8;
// }
::v-deep.btn-primary {
  background: #165b9b;
  border-color: #165b9b;
}
::v-deep .modal-content {
  margin-left: -228px;
  width: 142%;
}
.input-group-text {
  width: 162px;
  background: #0379fd;
  color: #fff;
}
</style>

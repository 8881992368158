<script>
//import VueCharts from "vue-chartjs";
import { Bar, mixins } from "vue-chartjs";
const { reactiveData } = mixins;

export default {
  extends: Bar,
  name: "Stacked",
  props: ["chartData", "labels"],
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            align: "top",
            anchor: "center",
            color: "blue",
            clamp: false,
            display: false,
            font: {
              weight: "bold",
              size: 16,
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    async drawReport() {
      this.renderChart(this.chartData, this.options);
    },
  },
  async mounted() {
    this.drawReport();
  },
  watch: {
    chartData(val) {
      this.drawReport();
    },
  },
};
</script>

<style lang="scss" scoped>
.dtc-chart {
  color: white;
}
</style>

<template>
  <section class="dtc-main-section main-container">
    <AllTables></AllTables>
    <AllTablesSecondType></AllTablesSecondType>
    <AllTablesThirdType></AllTablesThirdType>
    <AllTablesFourthType></AllTablesFourthType>
    <AllTablesTypeLast></AllTablesTypeLast>
    <b-overlay
      :show="isLoading"
      variant="dark"
      class="text-white"
      style="width: 100vw; height: 100vh;"
      opacity="0.98"
    >
      <div
        style="position:fixed;top:53%;left: 48.2%;z-index: 999;"
        v-show="isLoading"
      >
        Loading..
      </div>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="年學國考" active>
            <div class="dashboard">
              <!-- 1 -->
              <main class="">
                <aside>
                  <h6 class="mt-2 ml-3">入學率(%)</h6>
                  <span></span>
                  <span @click="openTable(1)" class="open-table">
                    <b-button variant="light" class="float-right mr-2" size="sm"
                      >詳情資料</b-button
                    >
                  </span>
                </aside>
                <!-- <PercentStacked
                  :stacked="true"
                  :chartData="getGraphData1(data1)"
                ></PercentStacked> -->
                <PercentStackedOneHundred
                  key="PercentStackedOneHundred"
                  :chartDataAll="getGraphData1(data1)"
                >
                </PercentStackedOneHundred>
              </main>
              <!-- 1 -->
              <main class="">
                <aside>
                  <h6 class="mt-2 ml-3">招考率(%)</h6>
                  <span></span>
                  <span @click="openTable(12)" class="open-table">
                    <b-button variant="light" class="float-right mr-2" size="sm"
                      >詳情資料</b-button
                    >
                  </span>
                </aside>
                <!-- <PercentStackedOneHundred
                  key="PercentStackedOneHundred"
                  :chartDataAll="getGraphData1(data12)"
                >
                </PercentStackedOneHundred> -->
                <Horizontal :chartData="getGraphData2(data12)"></Horizontal>
              </main>
              <!-- 2 -->
              <main class="">
                <aside>
                  <h6 class="mt-2 ml-3">退學率(%)</h6>
                  <span></span>
                  <span @click="openTable(2)" class="open-table"
                    ><b-button
                      variant="light"
                      class="float-right mr-2"
                      size="sm"
                      >詳情資料</b-button
                    ></span
                  >
                </aside>
                <Horizontal :chartData="getGraphData2(data2)"></Horizontal>
              </main>
              <!-- 3 -->
              <main class="main">
                <aside>
                  <h6 class="mt-2 ml-3">休學比例(%)</h6>
                  <span></span>
                  <span @click="openTable(3)" class="open-table"
                    ><b-button
                      variant="light"
                      class="float-right mr-2"
                      size="sm"
                      >詳情資料</b-button
                    ></span
                  >
                </aside>
                <Point :chartData="getPercentGraphData(data3)"></Point>
              </main>
              <!-- 4 -->
              <main class="main">
                <aside>
                  <h6 class="mt-2 ml-3">養成計畫預算執行率(%)</h6>
                  <b-button-group style="padding-left:10px;align-self:end;">
                    <b-button variant="info" @click="getBudgetExecutionData(1)"
                      >上學期</b-button
                    >
                    <b-button
                      variant="warning"
                      @click="getBudgetExecutionData(2)"
                      >下學期</b-button
                    >
                  </b-button-group>
                  <span @click="openTable(10)" class="open-table"
                    ><b-button
                      variant="light"
                      class="float-right mr-2"
                      size="sm"
                      >詳情資料</b-button
                    ></span
                  >
                </aside>
                <PercentStacked
                  :stacked="true"
                  :chartData="getGraphDataBudget(data10)"
                ></PercentStacked>
                <!-- <Cst :chartData="getGraphDataBudget(data10)"></Cst> -->
              </main>
              <!-- 5-->
              <main class="main">
                <aside>
                  <h6 class="mt-2 ml-3">培育人數</h6>
                  <span></span>
                  <span @click="openTable(4)" class="open-table"
                    ><b-button
                      variant="light"
                      class="float-right mr-2"
                      size="sm"
                      >詳情資料</b-button
                    ></span
                  >
                </aside>
                <PercentStacked
                  :stacked="true"
                  :chartData="getGraphData4(data4)"
                ></PercentStacked>
              </main>
            </div>
          </b-tab>
          <b-tab title="訓練期滿">
            <div class="dashboard">
              <!--6-->
              <main class="main">
                <aside>
                  <h6 class="mt-2 ml-3">未考醫事人員證照率(%)</h6>
                  <span></span>
                  <span @click="openTable(5)" class="open-table"
                    ><b-button
                      variant="light"
                      class="float-right mr-2"
                      size="sm"
                      >詳情資料</b-button
                    ></span
                  >
                </aside>
                <Point :chartData="getGraphData5(data5)"></Point>
              </main>
              <!--1-->
              <main class="">
                <aside>
                  <h6 class="mt-2 ml-3">考照未訓(專科訓練)(%)</h6>
                  <span></span>
                  <span @click="openTable(6)" class="open-table"
                    ><b-button
                      variant="light"
                      class="float-right mr-2"
                      size="sm"
                      >詳情資料</b-button
                    ></span
                  >
                </aside>
                <Point :chartData="getGraphData6(data6)"></Point>
                <!-- <Stacked :chartData="getGraphData6(data6)"></Stacked> -->
              </main>
              <!--2-->
              <main class="">
                <aside>
                  <h6 class="mt-2 ml-3">考照未訓(一般醫學訓練)(%)</h6>
                  <span></span>
                  <span @click="openTable(7)" class="open-table"
                    ><b-button
                      variant="light"
                      class="float-right mr-2"
                      size="sm"
                      >詳情資料</b-button
                    ></span
                  >
                </aside>
                <!-- <Horizontal :chartData="getGraphData(data7)"></Horizontal> -->
                <Point :chartData="getGraphData7(data7)"></Point>
              </main>
              <!--3-->
              <main class="main">
                <aside>
                  <h6 class="mt-2 ml-3">完訓未申請下鄉服務(%)</h6>
                  <span></span>
                  <span @click="openTable(8)" class="open-table"
                    ><b-button
                      variant="light"
                      class="float-right mr-2"
                      size="sm"
                      >詳情資料</b-button
                    ></span
                  >
                </aside>
                <!-- <Cst :chartData="getGraphDataNop(data8)"></Cst> -->
                <Point :chartData="getGraphData8(data8)"></Point>
              </main>
              <!--4-->
              <main class="main">
                <aside>
                  <h6 class="mt-2 ml-3">留任率(%)</h6>
                  <span></span>
                  <span @click="openTable(9)" class="open-table"
                    ><b-button
                      variant="light"
                      class="float-right mr-2"
                      size="sm"
                      >詳情資料</b-button
                    ></span
                  >
                </aside>
                <!-- <Horizontal :chartData="getGraphData(data9)"></Horizontal> -->
                <Point :chartData="getGraphData9(data9)"></Point>
              </main>
            </div>
          </b-tab>
          <template #tabs-end>
            <li role="presentation" class="nav-item align-self-center ml-2">
              <b-form-select
                :options="years"
                v-model="selYear"
                size="sm"
                class="inline"
                >cs</b-form-select
              >
            </li>
          </template>
        </b-tabs>
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import Cst from "./Cst.vue";
import Stacked from "./Stacked";
import PercentStacked from "./PercentStacked";
import PercentStackedOneHundred from "./PercentStackedOneHundred";
import Horizontal from "./Horizontal";
import Pie from "./Pie";
import Point from "./Point";
import AllTables from "./AllTables";
import AllTablesSecondType from "./AllTablesSecondType";
import AllTablesThirdType from "./AllTablesThirdType";
import AllTablesFourthType from "./AllTablesFourthType";
import AllTablesTypeLast from "./AllTablesTypeLast";
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";
import { store } from "@/store/global.js";

const year = new Date().getFullYear() - 1911;
let years = new Array(8).fill().map((s, i) => year + 1 - i);
export default {
  name: "App",
  components: {
    Cst,
    Stacked,
    Horizontal,
    Pie,
    Point,
    PercentStacked,
    PercentStackedOneHundred,
    AllTables,
    AllTablesSecondType,
    AllTablesThirdType,
    AllTablesFourthType,
    AllTablesTypeLast,
  },
  updated() {},
  data() {
    return {
      data5Array: [],
      data6Array: [],
      data7Array: [],
      data8Array: [],
      data9Array: [],
      semester: 1,
      isLoading: false,
      showDlg: 0,
      dataIndex: 5,
      fieldOption: {
        useSlot: true,
        itemClass: "dtc-ngrid field-item ellipsis",
        detectList: true,
      },
      columns: "repeat(field-num, 130px);",
      headerOption: {
        itemClass: "dtc-ngrid header-item center-all",
        useSlot: true,
        itemStyle: {},
      },
      data1: null,
      data12: null,
      data2: null,
      data3: null,
      data4: null,
      data5: null,
      data6: null,
      data7: null,
      data8: null,
      data9: null,
      data10: null,
      selYear: 109,
      // years: [
      //   {
      //     text: "109年",
      //     value: 109,
      //   },
      // ],
      years,
    };
  },
  computed: {
    table_1() {
      return {
        title: "入學率",
        header: header_1,
        items: this.data1 ? this.data1 : [],
      };
    },
    table_12() {
      return {
        title: "招考率",
        header: header_12,
        items: this.data1 ? this.data1 : [],
      };
    },
    table_2() {
      return {
        title: "退學率",
        header: header_1,
        items: this.data2,
      };
    },
    table_3() {
      return {
        title: "休學率",
        header: header_1,
        items: this.data3,
      };
    },
    table_4() {
      return {
        title: "培育人數",
        header: header_4,
        items: this.data4,
      };
    },
    table_5() {
      return {
        title: "未考醫事人員證照率",
        header: header_5,
        items: [this.data5],
      };
    },
    table_6() {
      return {
        title: "考照未訓(專科訓練)",
        header: header_4,
        items: this.data6,
      };
    },
    table_7() {
      return {
        title: "考照未訓(一般醫學訓練)",
        header: header_4,
        items: this.data7,
      };
    },
    table_8() {
      return {
        title: "完訓未申請下鄉服務",
        header: header_8,
        items: this.data8,
      };
    },
    table_9() {
      return {
        title: "留任率",
        header: header_4,
        items: this.data9,
      };
    },
    table_10() {
      return {
        title: "養成計畫預算執行率",
        header: header_10,
        items: this.data10,
      };
    },
    currentTable() {
      return this["table_" + this.dataIndex + ""];
    },
  },
  methods: {
    getPercentGraphData(source) {
      const fields = [
        // {
        //   fill: false,
        //   label: "休學比例",
        //   backgroundColor: "#f27173",
        // },
        {
          label: "原住民",
          backgroundColor: "#edcd2b",
          borderColor: "#edcd2b",
          fill: false,
        },
        {
          label: "離島",
          backgroundColor: "#f69630",
          borderColor: "#f69630",
          fill: false,
        },
        {
          label: "偏鄉",
          backgroundColor: "#00b301",
          borderColor: "#00b301",
          fill: false,
        },
        // {
        //   label: "原住民(女)",
        //   backgroundColor: "#B43993",
        //   borderColor: "#B43993",
        //   fill: false,
        // },
        // {
        //   label: "離島(女)",
        //   backgroundColor: "#C8819E",
        //   borderColor: "#C8819E",
        //   fill: false,
        // },
        // {
        //   label: "偏鄉(女)",
        //   backgroundColor: "#E47FD2",
        //   borderColor: "#E47FD2",
        //   fill: false,
        // },
      ];
      let obj = !source
        ? {}
        : {
            labels: source.map((s) => s.SchoolName),
            datasets: fields.map((s, si) => ({
              ...s,
              ...{
                // replaceData: source.map((v, i) => {
                //   return v.Rate;
                // }),
                // data: source.map((v, i) => {
                //   return v.Rate;
                // }),
                data: source.map((v, i) => {
                  // if (s.label.includes("男")) {
                  //   return v[`Rate_M${si + 1}`];
                  // }
                  return v[`Rate${si + 1}`];
                }),
              },
            })),
          };

      return obj;
    },
    // getGraphData1(source) {
    //   const fields = [
    //     {
    //       label: "原住民",
    //       backgroundColor: "#edcd2b",
    //       // stack: "Base",
    //     },
    //     {
    //       label: "離島",
    //       backgroundColor: "#f69630",
    //       // stack: "Base",
    //     },
    //     {
    //       label: "偏鄉",
    //       backgroundColor: "#00b301",
    //       // stack: "Base",
    //     },
    //     // {
    //     //   label: "原住民(女)",
    //     //   backgroundColor: "#B43993",
    //     //   stack: "Sensitivity",
    //     // },
    //     // {
    //     //   label: "離島(女)",
    //     //   backgroundColor: "#C8819E",
    //     //   stack: "Sensitivity",
    //     // },
    //     // {
    //     //   label: "偏鄉(女)",
    //     //   backgroundColor: "#E47FD2",
    //     //   stack: "Sensitivity",
    //     // },
    //     // {
    //     //   datalabels: {
    //     //     display: false,
    //     //   },
    //     //   yAxisID: "B",
    //     //   label: "比例%",
    //     //   type: "line",
    //     //   fill: false,
    //     //   borderColor: "#4BC0C000",
    //     //   backgroundColor: "red",
    //     // },
    //   ];
    //   let obj = !source
    //     ? {}
    //     : {
    //         labels: source.map((s) => s.SchoolName),
    //         datasets: fields.map((s, si) => ({
    //           ...s,
    //           ...{
    //             data: source.map((v, i) => {
    //               return v[`Rate${si + 1}`];

    //               // if (si === 4) {
    //               //   return v.Rate;
    //               // } else if (si === 3) {
    //               //   return v["Region1"] + v["Region2"] + v["Region3"];
    //               // }
    //               // return v[`Rate_F${si + 1}`];
    //             }),
    //           },
    //         })),
    //       };
    //   return obj;
    // },
    getGraphData1(source) {
      let array = !source
        ? []
        : source.map((s) => [s.SchoolName, s.Rate1, s.Rate2, s.Rate3, ""]);
      array.unshift([
        "Genre",
        "原住民",
        "離島",
        "偏鄉",
        { role: "annotation" },
      ]);

      return array;
      // return [];
    },
    getGraphData2(source) {
      const fields = [
        {
          label: "原住民",
          backgroundColor: "#edcd2b",
        },
        {
          label: "離島",
          backgroundColor: "#f69630",
        },
        {
          // datalabels: {
          //   color: "blue",
          //   labels: {
          //     title: {
          //       padding: { left: 50 },
          //       font: {
          //         weight: "bold",
          //       },
          //       align: "end",
          //       formatter: function(value, context) {
          //         if (context.dataset.title) {
          //           if (!context.dataset.title[context.dataIndex]) return "";
          //           return context.dataset.title[context.dataIndex] + "" + "%";
          //         }
          //       },
          //     },
          //     value: {},
          //   },
          // },
          label: "偏鄉",
          backgroundColor: "#00b301",
        },
        // {
        //   label: "原住民(女)",
        //   backgroundColor: "#B43993",
        // },
        // {
        //   label: "離島(女)",
        //   backgroundColor: "#C8819E",
        // },
        // {
        //   label: "偏鄉(女)",
        //   backgroundColor: "#E47FD2",
        // },
        // {
        //   label: "總計",
        //   backgroundColor: "#36A2EB",
        // },
      ];
      let obj = !source
        ? {}
        : {
            labels: source.map((s) => s.SchoolName),
            datasets: fields.map((s, si) => ({
              ...s,
              ...{
                title: source.map((v, i) => {
                  return v.Rate;
                }),
                data: source.map((v, i) => {
                  // if (si === 3) {
                  //   return v.Rate;
                  // }
                  // if (si === 3) {
                  //   return v["Region1"] + v["Region2"] + v["Region3"];
                  // }
                  // return v[`Rate_M${si + 1}`];
                  // if (s.label.includes("男")) {
                  //   return v[`Rate_M${si + 1}`];
                  // } else if (s.label.includes("女")) {
                  //   return v[`Rate_F${si - 2}`];
                  // }
                  return v[`Rate${si + 1}`];
                }),
              },
            })),
          };

      return obj;
    },
    getGraphData4(source) {
      const fields = [
        {
          label: "原住民",
          backgroundColor: "#edcd2b",
        },
        {
          label: "離島",
          backgroundColor: "#f69630",
        },
        {
          label: "偏鄉",
          backgroundColor: "#00b301",
        },
        // {
        //   label: "總計",
        //   backgroundColor: "#36A2EB",
        // },
      ];
      let obj = !source
        ? {}
        : {
            labels: source.map((s) => s.SchoolName),
            datasets: fields.map((s, si) => ({
              ...s,
              ...{
                data: source.map((v, i) => {
                  if (s.label == "原住民") {
                    return v.Country1;
                  } else if (s.label == "離島") {
                    return v.Country2;
                  } else if (s.label == "偏鄉") {
                    return v.Country3;
                  }
                  // if (si === 3) {
                  //   return v["Region1"] + v["Region2"] + v["Region3"];
                  // }
                  // return v[`Rate${si + 1}`];
                  // return Math.ceil(Math.random() * 100);
                }),
              },
            })),
          };

      return obj;
    },
    getGraphData5(source) {
      let obj = !source
        ? {}
        : {
            // labels: labels5,

            labels: source.map((s) => s.SchoolName),
            datasets: fieldSets5.map((s, si) => ({
              ...s,
              ...{
                data: this.data5Array,
                // data: source.reduce((acc, cur) => acc.push(cur.Rate), []),
              },
            })),
          };

      return obj;
    },
    getGraphData6(source) {
      let obj = !source
        ? {}
        : {
            labels: source.map((s) => s.SchoolName),
            datasets: fieldSets6.map((s, si) => ({
              ...s,
              ...{
                data: this.data6Array,
              },
            })),
          };

      return obj;
    },
    getGraphData7(source) {
      let obj = !source
        ? {}
        : {
            labels: source.map((s) => s.SchoolName),
            datasets: fieldSets7.map((s, si) => ({
              ...s,
              ...{
                data: this.data7Array,
              },
            })),
          };

      return obj;
    },
    getGraphData8(source) {
      let obj = !source
        ? {}
        : {
            labels: source.map((s) => s.SchoolName),
            datasets: fieldSets8.map((s, si) => ({
              ...s,
              ...{
                data: this.data8Array,
              },
            })),
          };

      return obj;
    },
    getGraphData9(source) {
      let obj = !source
        ? {}
        : {
            labels: source.map((s) => s.SchoolName),
            datasets: fieldSets9.map((s, si) => ({
              ...s,
              ...{
                data: this.data9Array,
              },
            })),
          };

      return obj;
    },
    getGraphDataNop(source) {
      const fields = [
        {
          label: "原住民",
          backgroundColor: "#edcd2b",
        },
        {
          label: "離島",
          backgroundColor: "#f69630",
        },
        {
          label: "偏鄉",
          backgroundColor: "#00b301",
        },
        // {
        //   label: "總計",
        //   backgroundColor: "#36A2EB",
        //   // replace: true,
        // },
      ];
      let obj = !source
        ? {}
        : {
            labels: source.map((s) => s.SchoolName),
            datasets: fields.map((s, si) => ({
              ...s,
              ...{
                replaceData: source.map((v, i) => {
                  return 0;
                }),
                data: source.map((v, i) => {
                  // if (si === 3) {
                  //   return v["Nop1"] + v["Nop2"] + v["Nop3"];
                  // }
                  // return v[`Nop${si + 1}`];
                  return Math.ceil(Math.random() * 100);
                }),
              },
            })),
          };

      return obj;
    },
    getGraphDataBudget(source) {
      const fieldSets10 = [
        {
          type: "bar",
          label: "申請核准經費(a)",
          backgroundColor: "#4BC0C0AA",
        },
        {
          type: "bar",
          label: "已核銷經費(b)",
          backgroundColor: "#f27173AA",
        },
        {
          datalabels: {
            display: false,
          },
          // yAxisID: "B",
          type: "line",
          fill: false,
          borderColor: "red",
          label: "執行率(b/a)％",
          // backgroundColor: "#36A2EBAA",
          backgroundColor: "red",
        },
      ];

      let obj = !source
        ? {}
        : {
            labels: source.map((s) => s.SchoolName),
            datasets: fieldSets10.map((s, si) => ({
              ...s,
              ...{
                data: source.map((v, i) => {
                  // return v[`Budget${si + 1}`];
                  if (s.type == "line") {
                    return this.semester == 1 ? v.Rate1 * 100 : v.Rate2 * 100;
                  } else if (s.label == "申請核准經費(a)") {
                    // return 200;
                    return this.semester == 1
                      ? v.FeeSettlement_1
                      : v.FeeSettlement_2;
                  } else if (s.label == "已核銷經費(b)") {
                    return this.semester == 1 ? v.FeeApprove_1 : v.FeeApprove_2;
                    // return 400;
                  }
                }),
              },
            })),
          };

      return obj;
    },
    getGraphData(source) {
      let obj = !source
        ? {}
        : {
            labels: source.map((s) => s.SchoolName),
            datasets: fieldSets.map((s, si) => ({
              ...s,
              ...{
                data: source.map((v, i) => {
                  return Math.ceil(Math.random() * 100);
                  // if (si === 4) {
                  //   return v.Rate;
                  // } else if (si === 3) {
                  //   return (
                  //     (v["Region1"] ? v["Region1"] : 0) +
                  //     (v["Region2"] ? v["Region2"] : 0) +
                  //     (v["Region3"] ? v["Region3"] : 0)
                  //   );
                  // }
                  // return v[`Region${si + 1}`] ? v[`Region${si + 1}`] : 0;
                }),
              },
            })),
          };

      return obj;
    },
    openTable(index) {
      // this.$bvModal.show("myDtcShowTable");
      //表格資料已經在mounted時給了 store.allTableItem
      if (index == 4) {
        this.$bvModal.show("myDtcShowTableThirdType");
      } else if (index == 10) {
        this.$bvModal.show("myDtcShowTableFouthType");
      } else if (index == 9) {
        this.$bvModal.show("myDtcShowTableLastType");
      }
      // else if (index == 9) {
      //   this.$bvModal.show("myDtcShowTable");
      // }
      else {
        this.$bvModal.show("myDtcShowTableSecondType");
      }

      store.allTableItem.nowtableIndex = index;
      // console.log("store.allTableItem", store.allTableItem);
    },
    async getData() {
      //入學率(註冊率)
      this.data1 = await window.axios.get(
        `/Statistical01/GetStatChart01?year=${this.selYear}`
      );
      //招考率
      // this.data12 = await window.axios.get(
      //   `/Statistical01/GetStatChart01?year=${this.selYear}`
      // );
      this.data12 = [];
      //退學率
      this.data2 = await window.axios.get(
        `/Statistical01/GetStatChart02?year=${this.selYear}`
      );
      //休學率
      this.data3 = await window.axios.get(
        `/Statistical01/GetStatChart03?year=${this.selYear}`
      );
      //地區培育人數
      this.data4 = await window.axios.get(
        `/Statistical05/GetStatChart?year=${this.selYear}`
      );
      //未考醫事人員證照率
      this.data5 = await window.axios.get(
        `/Statistical06/GetStatChart?year=${this.selYear}`
      );
      let arr5 = [];
      this.data5.forEach((s, i) => {
        arr5.push(s.Rate);
      });
      this.data5Array = arr5;

      //考照未訓(專科訓練)3
      this.data6 = await window.axios.get(
        `/Statistical07/GetStatChart?year=${this.selYear}`
      );
      let arr6 = [];
      this.data6.forEach((s, i) => {
        arr6.push(s.Rate);
      });
      this.data6Array = arr6;

      //考照未訓(一般醫學訓練)3
      this.data7 = await window.axios.get(
        `/Statistical08/GetStatChart?year=${this.selYear}`
      );
      let arr7 = [];
      this.data7.forEach((s, i) => {
        arr7.push(s.Rate);
      });
      this.data7Array = arr7;

      //完訓未申請下鄉服務
      this.data8 = await window.axios.get(
        `/Statistical09/GetStatChart?year=${this.selYear}`
      );
      let arr8 = [];
      this.data8.forEach((s, i) => {
        arr8.push(s.Rate);
      });
      this.data8Array = arr8;

      //留任率
      this.data9 = await window.axios.get(
        `/Statistical10/GetStatChart?year=${this.selYear}`
      );
      let arr9 = [];
      this.data9.forEach((s, i) => {
        arr9.push(s.Rate);
      });
      this.data9Array = arr9;

      //養成計畫預算執行率
      this.data10 = await window.axios.get(
        `/Statistical04/GetStatChart?year=${this.selYear}`
      );
      this.data10 = this.data10.map((s) => {
        s.FeeApprove_1 = s.Fee.reduce((acc, cur) => acc + +cur.Approve_1, 0);
        s.FeeApprove_2 = s.Fee.reduce((acc, cur) => acc + +cur.Approve_2, 0);
        s.FeeSettlement_1 = s.Fee.reduce(
          (acc, cur) => acc + +cur.Settlement_1,
          0
        );
        s.FeeSettlement_2 = s.Fee.reduce(
          (acc, cur) => acc + +cur.Settlement_2,
          0
        );
        return s;
      });
      this.data10 = this.data10.map((s) => {
        s.Rate1 =
          !isNaN(s.FeeSettlement_1 / s.FeeApprove_1) &&
          Number.isFinite(s.FeeSettlement_1 / s.FeeApprove_1)
            ? s.FeeSettlement_1 / s.FeeApprove_1
            : 0;
        s.Rate2 =
          !isNaN(s.FeeSettlement_2 / s.FeeApprove_2) &&
          Number.isFinite(s.FeeSettlement_2 / s.FeeApprove_2)
            ? s.FeeSettlement_2 / s.FeeApprove_2
            : 0;
        return s;
      });
    },
    async getBudgetExecutionData(key) {
      this.semester = key;
      this.data10 = await window.axios.get(
        `/Statistical04/GetStatChart?year=${this.selYear}`
      );
      this.data10 = this.data10.map((s) => {
        s.FeeApprove_1 = s.Fee.reduce((acc, cur) => acc + +cur.Approve_1, 0);
        s.FeeApprove_2 = s.Fee.reduce((acc, cur) => acc + +cur.Approve_2, 0);
        s.FeeSettlement_1 = s.Fee.reduce(
          (acc, cur) => acc + +cur.Settlement_1,
          0
        );
        s.FeeSettlement_2 = s.Fee.reduce(
          (acc, cur) => acc + +cur.Settlement_2,
          0
        );
        return s;
      });
      this.data10 = this.data10.map((s) => {
        s.Rate1 =
          !isNaN(s.FeeSettlement_1 / s.FeeApprove_1) &&
          Number.isFinite(s.FeeSettlement_1 / s.FeeApprove_1)
            ? s.FeeSettlement_1 / s.FeeApprove_1
            : 0;
        s.Rate2 =
          !isNaN(s.FeeSettlement_2 / s.FeeApprove_2) &&
          Number.isFinite(s.FeeSettlement_2 / s.FeeApprove_2)
            ? s.FeeSettlement_2 / s.FeeApprove_2
            : 0;
        return s;
      });
    },
    setDataToStore() {
      store.allTableItem = {
        table1: this.table_1,
        table2: this.table_2,
        table3: this.table_3,
        table4: this.table_4,
        table5: this.table_5,
        table6: this.table_6,
        table7: this.table_7,
        table8: this.table_8,
        table9: this.table_9,
        table10: this.table_10,
        table12: this.table_12,
      };
    },
  },
  watch: {
    async selYear(v) {
      this.isLoading = true;
      try {
        await this.getData();
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getData();
    this.isLoading = false;
    await this.setDataToStore();
  },
};
Chart.defaults.global.defaultFontStyle = "bold";
Chart.defaults.global.defaultFontColor = "#343a40";
Chart.defaults.global.defaultFontFamily = "Microsoft JhengHei";
Chart.helpers.merge(Chart.defaults.global.plugins.datalabels, {
  anchor: "end",
  align: "start",
});

const header_1 = [
  { key: "SchoolName", text: "各校別" },
  { key: "Year", text: "各學年" },
  { key: "People", text: "註冊人數" },
  { key: "SchoolPeople", text: "核定人數" },
  { key: "Region1", text: "原住民" },
  { key: "Region2", text: "離島籍" },
  { key: "Region3", text: "偏鄉籍" },
  { key: "Rate", text: "入學率" },
];
const header_12 = [
  { key: "SchoolName", text: "各校別" },
  { key: "Year", text: "各學年" },
  { key: "People", text: "註冊人數" },
  { key: "SchoolPeople", text: "核定人數" },
  { key: "Region1", text: "原住民" },
  { key: "Region2", text: "離島籍" },
  { key: "Region3", text: "偏鄉籍" },
  { key: "Rate", text: "招考率" },
];

const header_4 = [
  { key: "SchoolName", text: "各校別" },
  { key: "Year", text: "各學年" },
  { key: "Region1", text: "原住民" },
  { key: "Region2", text: "離島籍" },
  { key: "Region3", text: "偏鄉籍" },
  { key: "TotalPeople", text: "培育人數" },
];

const labels5 = [
  "醫師",
  "牙醫師",
  "護理師",
  "藥師",
  "職能治療",
  "物理治療",
  "語言及聽力治療師",
  "臨床心理",
  "醫學生物技檢驗師",
  "呼吸治療",
  "營養師",
  "護理助產師",
];
const header_5 = [
  { key: "Year", text: "年度" },
  { key: "Major01", text: labels5[0] },
  { key: "Major02", text: labels5[1] },
  { key: "Major03", text: labels5[2] },
  { key: "Major04", text: labels5[3] },
  { key: "Major05", text: labels5[4] },
  { key: "Major06", text: labels5[5] },
  { key: "Major07", text: labels5[6] },
  { key: "Major08", text: labels5[7] },
  { key: "Major09", text: labels5[8] },
  { key: "Major10", text: labels5[9] },
  { key: "Major11", text: labels5[10] },
  { key: "Major12", text: labels5[11] },
];
const header_8 = [
  { key: "SchoolName", text: "各校別" },
  { key: "Year", text: "各學年" },

  { key: "Nop1", text: "完訓未服務" },
  { key: "Nop1", text: "完成專科訓" },
  { key: "Nop1", text: "百分比" },
  { key: "Subtotal", text: "人數" },
];
const header_10 = [
  { key: "SchoolName", text: "各校別" },
  { key: "Year", text: "各學年" },
  { key: "Term", text: "學期" },
  { key: "Budget1", text: "已核銷經費(b)" },
  { key: "Budget2", text: "申請核准經費(a)" },
  { key: "Budget3", text: "執行率(b/a)" },
];
const fieldSets5 = [
  {
    label: "未考醫事人員證照率%",
    backgroundColor: "#eccc3d",
    borderColor: "#eccc3d",
    fill: false,
  },
];
const fieldSets6 = [
  {
    label: "考照未訓(專科訓練)%",
    backgroundColor: "#00b301",
    borderColor: "#00b301",
    fill: false,
  },
];
const fieldSets7 = [
  {
    label: "考照未訓(一般醫學訓練)%",
    backgroundColor: "#f69630",
    borderColor: "#f69630",
    fill: false,
  },
];
const fieldSets8 = [
  {
    label: "完訓未申請下鄉服務%",
    backgroundColor: "#00b301",
    borderColor: "#00b301",
    fill: false,
  },
];
const fieldSets9 = [
  {
    label: "留任率%",
    backgroundColor: "#eccc3d",
    borderColor: "#eccc3d",
    fill: false,
  },
];
const fieldSets = [
  {
    label: "原住民",
    backgroundColor: "#edcd2b",
  },
  {
    label: "離島",
    backgroundColor: "#f69630",
  },
  {
    label: "偏鄉",
    backgroundColor: "#00b301",
  },
  // {
  //   label: "總計",
  //   backgroundColor: "#36A2EB",
  // },
];
</script>

<style lang="scss" scoped>
.main-container {
  position: relative;
}
::v-deep .card-body {
  padding: 0px;
}
::v-deep .dtc-ngrid.field-item {
  font-size: 14px;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: keep-all;
  text-overflow: ellipsis;
  padding: 3px 0;
  -webkit-box-orient: vertical;
  text-align: center;
  > span {
    display: block;
  }
}
//  :style="i % 2 == 0 ? 'background-color: #F5F5F5;' : 'background-color: #E0E0E0;'"
::v-deep .grid:nth-child(even) {
  background-color: #f5f5f5;
  > div {
    // background-color: #f5f5f5;
  }
}
::v-deep .grid:nth-child(odd) {
  background-color: #e0e0e0;
  > div {
    // background-color: #e0e0e0;
  }
}

.mask {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #00000022;
  z-index: 1;
}
.table-popup {
  // width: 1800px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  border: 1px solid #00000044;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 860px;
  min-width: 100px;
  min-height: 100px;
  background-color: white;
  z-index: 1;
  > div {
    padding: 10px;
    min-height: 300px;
    max-height: 450px;
    overflow: auto;
  }
  overflow: auto;
}
.dashboard {
  width: 100%;
  background: gray;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 5px;
  > main {
    display: grid;
    border-radius: 10px;
    background: white;
    min-width: 420px;
    max-height: 45vh;
    height: 47vh;
    grid-template-rows: 30px calc(100% - 60px);
    grid-template-columns: calc(100% - 5px);
    padding: 10px;
    color: black;
    gap: 1rem;
    h6 {
      font-size: 18px;
    }
  }
}
@media (max-width: 1300px) {
  .dashboard {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .dashboard {
    grid-template-columns: repeat(1, 1fr);
  }
}

aside {
  display: grid;
  grid-template-columns: 238px repeat(2, 1fr);
}
.open-table {
  cursor: pointer;
  // color: #6b645a;
  color: #6b645ac7;
  margin: 2px auto 0;
  font-size: 17.5px;
  font-weight: 500;
}
::v-deep .btn-light {
  // background: #165b9b;
  // border-color: #165b9b;
  background: #7d2da1;
  border-color: #7d2da1;

  color: white;
}
</style>
